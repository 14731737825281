
export const TaskType = ["TASK", "TEST", "DEPLOYING", "INTEGRATION"];
export const Priority = ["LOW", "MEDIUM", "HIGH"];
export const TaskStatus = ["UNSTARTED", "IN_PROGRESS", "PAUSED", "FINISHED"];
export const SprintStatus = ["UNSTARTED", "IN_PROGRESS", "PAUSED", "FINISHED"];
export const ProjectStatus = ["UNSTARTED", "IN_PROGRESS", "PAUSED", "FINISHED"];

export const EnumsColors = {
  "UNSTARTED": {text: "#000", bg: "rgba(0, 0, 0, 0.1)"},
  "IN_PROGRESS": {text: "#5A97BD", bg: "#F5FAFC"},
  "PAUSED": {text: "#e19404", bg: "#fff6e6"},
  "STOPPED": {text: "#5D1715", bg: "#FFE2DD"},
  "FINISHED": {text: "#6C9B7D", bg: "#F7FAF7"},
  "HIGH": {text: "#5D1715", bg: "#FFE2DD"},
  "MEDIUM": {text: "#5A97BD", bg: "#edf4f8"},
  "LOW": {text: "#6C9B7D", bg: "#f0f5f2"},
}
