import React, { useEffect, useState } from 'react';

import {loginEvent} from '../Contexts/events';
import {getMyInfo} from "../services/accountService";

export default function IsLoggedIn() {

  const [authState, setAuthState] = useState(null);
  const [isMaster, setIsMaster] = useState(null);

  const isUserLogged = ()=>{
    if (localStorage.getItem('accessToken')) {
      setAuthState(true);
    } else if (localStorage.getItem('refreshToken')) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  }

  const isUserMaster = ()=>{
    if (localStorage.getItem('accessToken')) {
      getMyInfo()
        .then((res)=>{
          const username = res.username;
          if (username.split('_')[0] === "master"){
            setIsMaster(true);
          }else{
            setIsMaster(false);
          }
        })
        .catch((err)=> console.log(err) )
    }
  }

  useEffect(()=>{
    isUserLogged();
    isUserMaster();
  },[])

  useEffect(() => {
    const handleLoginEvent = (state) => {
      setAuthState(state);
    };
    const handleMasterEvent = (state)=>{
      setIsMaster(state);
    }

    loginEvent.on('login', handleLoginEvent);
    loginEvent.on('master', handleMasterEvent);

    return () => {
      loginEvent.removeListener('login', handleLoginEvent);
      loginEvent.removeListener('master', handleMasterEvent);
    };
  }, [loginEvent]);

  return {isLogged: authState, isMaster: isMaster};

}
