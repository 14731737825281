import React, {useContext, useRef, useState, useEffect} from 'react';
import styles from '../Styles/overview.module.scss';
import { useTranslation } from 'react-i18next';
import { FiPhone, FiMonitor, FiCalendar, FiUser, FiEdit2, FiPlusCircle, FiBookmark, FiX, FiClock, FiUsers, FiLoader, FiArrowDownCircle, FiSearch, FiFolder, FiChevronLeft, FiChevronDown, FiChevronUp, FiTrash, FiMessageCircle } from 'react-icons/fi';
import { LuTable } from "react-icons/lu";
import { DiScrum } from "react-icons/di";
import { TbColumns3 } from "react-icons/tb";
import { GiSprint } from "react-icons/gi";
import { GrPlan } from "react-icons/gr";
import {Link, useNavigate, useParams} from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DatePicker from "react-datepicker";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import cx from 'classnames';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';

import { UserContext } from '../Contexts/UserContext';
import { CheckBox, Row } from '../Styles/DefaultComponents';
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/fr';
import {ReactComponent as SprintIcon} from '../Assets/sprintIcon.svg';
import SideModal from './SideModal';
import TasksTimeView from './TasksTimeView';
import { AnimatePresence } from 'framer-motion';
import SprintView from './SprintView';
import {
  createTasks,
  deleteTask, getAllFinishedTasks,
  getAllTasks,
  getPriorities,
  updateTasks,
  updateTaskStatus
} from "../services/taskService";
import {getAllUsers, getUsersInfoByIds} from "../services/usersService";
import {createProject, getAllProjectStatus} from "../services/projectService";
import Modal from "./Modal";
import {createSprint, getAllSprint, getAllSprintStatus} from "../services/sprintService";
import {EnumsColors, Priority, SprintStatus, TaskStatus} from "../Ressources/enums";
import {getAllTenantUsers, getUserByTenantByPage} from "../services/tenantUsersService";

const tasksTest = [
  { id: 1, task: 'Rédiger une proposition de projet', assignedTo: 'Adil Stifi', dueDate: '08/01/2024', status: "Pas commencé", priority: "Low", subTasks: [{id: 1, subTask: "test 1", status: "Pas commencé"} ,{id: 2, subTask: "test 2", status: "En cours"}] },
  { id: 2, task: 'Planifier la réunion de lancement', assignedTo: 'Adil Stifi', dueDate: '03/01/2024', status: "En cours", priority: "Medium", subTasks: [] },
  { id: 3, task: 'Consulter les résultats de la recherche', assignedTo: 'Adil Stifi', dueDate: '17/01/2024', status: "Terminé", priority: "Hight", subTasks: [] }
];

const status = [
  {id: "1", title: "Pas commencé", bgColor: "rgba(0, 0, 0, 0.1)"},
  {id: "2", title: "En cours", bgColor: "#D3E5EF"},
  {id: "3", title: "En pause", bgColor: "#FEF9C3"},
  {id: "4", title: "Terminé", bgColor: "#DBEDDB"},
];

const priorities = [
  {id: "1", title: "Low", bgColor: "rgba(0, 0, 0, 0.1)"},
  {id: "2", title: "Medium", bgColor: "#FEEBC8"},
  {id: "3", title: "High", bgColor: "#FFE2DD"},
];

const sprintsDataTest = [
  { id: 'planned', name: "Planned" },
  { id: 'inDevelopment', name: "In Development" },
  { id: 'readyForTest', name: "Ready for Test" },
  { id: 'readyForReview', name: "Ready for Review" },
  { id: 'sprint_1', name: "Sprint 1" },
  { id: 'sprint_2', name: "Sprint 2" },
];

const taskStatus = ["UNSTARTED", "IN_PROGRESS", "PAUSED", "FINISHED"];

function NewSprintModal({close, projectId}){

  const {t} = useTranslation();
  const statusSelectRef = useRef(null);

  const [name, setName] = useState("");
  const [deadline, setDeadLine] = useState(null);
  const [status, setStatus] = useState({});
  const [isStatusSelectOpen, setIsStatusSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sprintData, setSprintData] = useState({
    name: "",
    status: SprintStatus[0],
    projectId: projectId,
    startDate: null,
    endDate: null,
  });

  const changeField = (name, value)=>{
    setSprintData({...sprintData, [name]: value});
  }

  const clickStatus = (it)=>{
    setIsStatusSelectOpen(false);
    changeField("status", it);
  }

  const submit = ()=>{
    setLoading(true);
    const newSprintData = {
      "name": sprintData.name,
      "status": sprintData.status,
      projectId,
      startDate: sprintData.startDate,
      endDate: sprintData.endDate,
    }
    createSprint(newSprintData)
      .then((res)=> {
        console.log(res);
        close();
      })
      .catch((err)=> console.log(err))
      .finally(()=> setLoading(false));
  }

  return(
    <div className='min-h-[35vh] h-max flex flex-col justify-between' >
      <div>
        <div className='grid grid-cols-4 gap-4 mb-3'>
          <div className='col-span-3 relative'>
            {/*{ sprintData.name.length > 0 &&*/}
            {/*  <label className="absolute top-[-8px] left-3 bg-white px-2 text-xs font-semibold text-gray-400">*/}
            {/*    {t("SPRINT.SPRINT_NAME")}*/}
            {/*  </label>*/}
            {/*}*/}
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>{t("SPRINT.SPRINT_NAME")}</label>
            </div>
            <input
              placeholder={t("SPRINT.SPRINT_NAME")}
              className='text-sm border py-2 px-3 h-10 rounded-lg w-full'
              value={sprintData.name}
              onChange={(e) => changeField("name", e.target.value)}
            />
          </div>
          <div className="relative flex flex-col justify-between">
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>{t("SPRINT.SPRINT_STATUS")}</label>
            </div>
            <div className='relative min-h-10 flex items-center'>
              <button
                onClick={() => setIsStatusSelectOpen(true)}
                className={cx(styles.status)}
                style={{
                  color: EnumsColors[sprintData.status].text,
                  backgroundColor: EnumsColors[sprintData.status].bg
                }}
              >
                • {t(`COMMON.TASK_STATUS.${sprintData.status}`)}
              </button>
              {
                isStatusSelectOpen &&
                <div className={styles.floatStatusMenu} ref={statusSelectRef}>
                  <ul>
                    <li onClick={() => setIsStatusSelectOpen(false)}
                        className={styles.currStatus}>{t(`COMMON.TASK_STATUS.${sprintData.status}`)}</li>
                    {
                      SprintStatus.map((it) =>
                        <li
                          key={it} onClick={() => clickStatus(it)}
                          className={styles.status}
                          style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}
                        >
                          • {t(`COMMON.TASK_STATUS.${it}`)}
                        </li>
                      )
                    }
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3 mb-3">
          <div className='col-span-1'>
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>Date debut</label>
            </div>
            <DatePicker
              selected={sprintData.startDate}
              onChange={(e) => changeField("startDate", e)}
              type="text"
              dateFormat="dd/MM/yyyy"
              placeholderText="Date debut"
              className='h-10 px-3 border rounded-xl text-sm text-gray-700 w-full'
              isClearable
              wrapperClassName='w-full'
            />
          </div>
          <div className='col-span-1'>
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>Date fin</label>
            </div>
            <DatePicker
              selected={sprintData.endDate}
              onChange={(e) => changeField("endDate", e)}
              type="text"
              dateFormat="dd/MM/yyyy"
              placeholderText="Date fin"
              className='h-10 px-3 border rounded-xl text-sm text-gray-700 w-full'
              isClearable
              wrapperClassName='w-full'
            />
          </div>
        </div>
      </div>
      <div className='mt-6 flex items-center justify-end min-w-[40vw]'>
        {!loading &&
          <button onClick={() => submit()} className={styles.mainBtn}>
            {t("COMMON.SAVE")}
          </button>
        }
        {loading &&
          <div className="flex items-center justify-center px-4 w-28 h-10 bg-purple-light rounded-lg">
            <div className="loader"/>
          </div>
        }
      </div>
    </div>
  )
}

function TableRow({task, openTask}) {

  const {t} = useTranslation();
  const statusMenuRef = useRef(null);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
  const [assignedTo, setAssignedTo] = useState({});

  const changeStatus = (newStatus) => {
    task.status = newStatus;
    setIsStatusMenuOpen(false);
  }

  const getAssignedTo = async (id)=>{
    getUsersInfoByIds([task.assignee])
      .then((res) => {
        setAssignedTo(res[0]);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if(Object.keys(task).length > 0){
      getAssignedTo();
    }
  }, [task]);

  const handleClickOutside = (event) => {
    if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
      setIsStatusMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <tr key={task.id} >
      <td className='cursor-pointer hover:text-purple-light' onClick={() => openTask(task)}>{task.name}</td>
      <td>{assignedTo.firstName} {assignedTo.lastName}</td>
      <td onClick={()=> console.log(task) } >{moment(task?.shouldStartAt).add(task.estimation, 'day').format('DD-MM-YYYY')}</td>
      <td>
        <div className='relative w-max'>
          <button
            onClick={() => setIsStatusMenuOpen(true)}
            className={cx(styles.status)}
            style={{color: EnumsColors[task.status].text, backgroundColor: EnumsColors[task.status].bg}}
          >
            {t(`COMMON.TASK_STATUS.${task.status}`)}
          </button>
          {
            isStatusMenuOpen &&
            <div className={styles.floatStatusMenu} ref={statusMenuRef} >
              <ul>
                <li className={styles.currStatus} >{t(`COMMON.TASK_STATUS.${task.status}`)}</li>
                {
                  taskStatus.map((it) =>
                    <li
                      key={it}
                      onClick={(e) => changeStatus(it)}
                      className={cx(styles.status,{[styles.progress]: it === "IN_PROGRESS", [styles.finish]: it === "FINISHED", [styles.pause]: it === "PAUSED"})}>
                      • {t(`COMMON.TASK_STATUS.${it}`)}
                    </li>
                  )
                }
              </ul>
            </div>
          }
        </div>
      </td>
      <td>
        project name
      </td>
      <td>
        <div
          style={{color: EnumsColors[task.priority].text, backgroundColor: EnumsColors[task.priority].bg}}
          className={styles.priority}
        >
          {t(`COMMON.TASK_PRIORITY.${task.priority}`)}
        </div>
      </td>
    </tr>
  )
}

export function TableView({openTask, projectId}){

  const { t } = useTranslation();
  const [tasksData, setTasksData] = useState([]);

  const fetchTasks = async ()=>{
    const tasksData= {
      "keyWord": "",
      "projectId": projectId
    }
    const params = {
      page: 1,
      size: 10,
      sort: `id,desc`,
    };
    let allTasks = [];
    await getAllTasks(tasksData, params)
      .then((res)=> {
        allTasks.push(...res.content);
      })
      .catch((err)=> console.log(err) );

    await getAllFinishedTasks(tasksData, params)
      .then((res)=>{
        allTasks.push(...res.content);
      })
      .catch((err)=> console.log(err) );

    setTasksData(allTasks);
  }

  useEffect(()=>{
    fetchTasks();
  }, [])

  return(
    <table className={styles.tableView} >
      <thead>
        <tr>
          <th>{t("PROJECT.TABLE.NAME")}</th>
          <th>{t("PROJECT.TABLE.ASSIGNED_TO")}</th>
          <th>{t("PROJECT.TABLE.DUE_DATE")}</th>
          <th>{t("PROJECT.TABLE.STATUS")}</th>
          <th>{t("PROJECT.TABLE.PROJECT")}</th>
          <th>{t("PROJECT.TABLE.PRIORITY")}</th>
        </tr>
      </thead>
      <tbody>
        {tasksData.map(task => <TableRow key={task.id} task={task} openTask={openTask} /> )}
      </tbody>
    </table>
  )
}

function TaskContainer({task, index, openTask, isDragging}){

  const {t} = useTranslation();
  const subTasksRef = useRef(null);
  const [subTasksHeight, setSubTasksHeight] = useState(0);
  const [isSubTasksShowen, setIsSubTasksShowen] = useState(true);
  const [assignedTo, setAssignedTo] = useState({});

  useEffect(() => {
    if (subTasksRef.current) {
      const height = subTasksRef.current.offsetHeight;
      setSubTasksHeight(height-20);
    }
  }, [isSubTasksShowen]);

  const getAssignedTo = async (id)=>{
    getUsersInfoByIds([task.assignee])
      .then((res) => {
        setAssignedTo(res[0]);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if(Object.keys(task).length > 0){
      getAssignedTo();
    }
  }, [task]);

  return(
    <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
      {(provided) => (
        <div className='mb-4 flex flex-col items-end' >
          <div  onClick={()=> openTask(task) } ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.task}>
            <div className='flex items-center gap-1.5 text-xs text-gray-500 mb-4' >
              <FiFolder size={12}  />
              <p >Project name</p>
            </div>
            <p className='font-semibold mb-4' >{task.name}</p>
            <div className='flex items-center gap-2 text-gray-500' >
              <div>
                <div
                  style={{color: EnumsColors[task.priority].text, backgroundColor: EnumsColors[task.priority].bg}}
                  className={styles.priority} >
                  {t(`COMMON.TASK_PRIORITY.${task.priority}`)}
                </div>
              </div>
              <span>•</span>
              <div className='flex items-center text-xs gap-1.5 max-w-[35%]' >
                <FiUser size={12} />
                <span className="truncate" >{assignedTo?.firstName} {assignedTo.lastName}</span>
              </div>
              <span>•</span>
              <div className='flex items-center gap-1.5 text-xs max-w-[35%] overflow-hidden' >
                <FiClock size={12} />
                <span className="truncate" >{moment(task?.shouldStartAt).add(task.estimation, 'day').format('DD-MM-YYYY')}</span>
              </div>
            </div>
            <div className={styles.taskEditIcon} >
              <FiEdit2/>
            </div>
            { task?.subTasks.length > 0 &&
              <div onClick={(e)=>{ e.stopPropagation(); setIsSubTasksShowen(!isSubTasksShowen) }} className='absolute z-20 w-6 h-6 bg-white bottom-[-12px] right-2 border rounded-full flex items-center justify-center hover:border-purple-light hover:text-purple-light' >
                { isSubTasksShowen ? <FiChevronUp/> : <FiChevronDown/>}
              </div>
            }
            { !isDragging ? isSubTasksShowen && <div className='absolute border bottom-0 left-[10%] translate-y-[100%]' style={{height: subTasksHeight+'px'}} /> : null}
          </div>
          { !isDragging ? isSubTasksShowen &&
            <div className='w-[80%]' ref={subTasksRef} >
              {
                task?.subTasks.map((it)=>
                  <div key={it.id} onClick={()=> openTask(task) } className='bg-white border p-2 my-1 rounded-lg w-full relative cursor-pointer hover:bg-gray-100' >
                    <div className='absolute top-[50%] border w-[12%] left-[-12%]' />
                    <div className='flex items-center justify-between' >
                      <p className='text-sm font-semibold' >{it.name}</p>
                      {/* <p className='text-xs font-semibold' >{it.status}</p> */}
                    </div>
                  </div>
                )
              }
            </div>
            : null
          }
        </div>
      )}
    </Draggable>
  )
}

const KanbanColumn = ({ isDragging, title, tasks, bgColor, titleColor, droppableId, openTask }) => {

  const {t} = useTranslation();

  return (
    <div className={styles.kanbanColumn} style={{backgroundColor: bgColor}}>
      <div className={styles.header} style={{color: titleColor}}>
        <span>• {t(`COMMON.TASK_STATUS.${title}`)}</span>
        <FiPlusCircle
          size={16}
          className='cursor-pointer'
          onClick={() => openTask({
            name: "",
            assignee: null,
            shouldStartAt: "",
            estimation: 1,
            sprint: "",
            status: title,
            priority: "LOW",
            subTasks: []
          })}
        />
      </div>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={styles.content}>
            {tasks.map((task, index) => (
              <TaskContainer key={task.id} task={task} index={index} openTask={openTask} isDragging={isDragging}/>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export function KanbanView({openTask, projectId, newFetch}) {

  const { t } = useTranslation();
  const mainStatus ={
    "UNSTARTED": "Pas commencé",
    "IN_PROGRESS": "En cours",
    "PAUSED": "En pause",
    "FINISHED": "Terminé"
  }
  const [tasksData, setTasksData] = useState([]);
  const [tasks, setTasks] = useState({
    UNSTARTED: [],
    IN_PROGRESS: [],
    PAUSED: [],
    FINISHED: []
  });
  const [isDragging, setIsDragging] = useState(false);

  const fetchTasks = async ()=>{
    const tasksData= {
      "keyWord": "",
      "projectId": projectId
    }
    const params = {
      page: 1,
      size: 10,
      sort: `id,desc`,
    };
    let allTasks = [];
    await getAllTasks(tasksData, params)
      .then((res)=> {
        allTasks.push(...res.content);
      })
      .catch((err)=> console.log(err) );

    await getAllFinishedTasks(tasksData, params)
      .then((res)=>{
        allTasks.push(...res.content);
      })
      .catch((err)=> console.log(err) );

    setTasksData(allTasks);
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const taskId = tasks[source.droppableId][source.index].id;
    const newStatus = destination.droppableId

    updateTaskStatus({"status": newStatus}, taskId)
      .then((res)=> {
        fetchTasks();
      })
      .catch((err)=> console.log(err) );

    const newResult = move(
      tasks[source.droppableId],
      tasks[destination.droppableId],
      source,
      destination
    );
     setTasks({
      ...tasks,
      ...newResult,
     });

    // if (source.droppableId === destination.droppableId) {
    //   const items = reorder(
    //     tasks[source.droppableId],
    //     source.index,
    //     destination.index
    //   );
    //
    //   const newState = {
    //     ...tasks,
    //     [source.droppableId]: items,
    //   };
    //
    //   setTasks(newState);
    // } else {
    //   const result = move(
    //     tasks[source.droppableId],
    //     tasks[destination.droppableId],
    //     source,
    //     destination
    //   );
    //
    //   console.log(result);
    //
    //   setTasks({
    //     ...tasks,
    //     ...result,
    //   });
    // }
    setIsDragging(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);
    destClone[droppableDestination.index].status = mainStatus[droppableDestination.droppableId]
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const onDragStart = (start) => {
    setIsDragging(true);
  };

  useEffect(() => {
    if(tasksData.length > 0){
      const initTasks = {
        UNSTARTED: tasksData.filter(task => task.status === "UNSTARTED"),
        IN_PROGRESS: tasksData.filter(task => task.status === "IN_PROGRESS"),
        PAUSED: tasksData.filter(task => task.status === "PAUSED"),
        FINISHED: tasksData.filter(task => task.status === "FINISHED")
      };
      setTasks(initTasks);
    }
  }, [tasksData]);

  useEffect(() => {
    fetchTasks();
  }, [newFetch]);

  return (
    <div className={styles.kanbanBoard}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} >
        <KanbanColumn isDragging={isDragging} title="UNSTARTED" tasks={tasks.UNSTARTED} openTask={openTask} bgColor="#FCFCFA" titleColor="#000" droppableId="UNSTARTED" />
        <KanbanColumn isDragging={isDragging} title="IN_PROGRESS" tasks={tasks.IN_PROGRESS} openTask={openTask} bgColor="#F5FAFC" titleColor="#5A97BD" droppableId="IN_PROGRESS" />
        <KanbanColumn isDragging={isDragging} title="PAUSED" tasks={tasks.PAUSED} openTask={openTask} bgColor="#fffdef" titleColor="#000" droppableId="PAUSED" />
        <KanbanColumn isDragging={isDragging} title="FINISHED" tasks={tasks.FINISHED} openTask={openTask} bgColor="#F7FAF7" titleColor="#6C9B7D" droppableId="FINISHED" />
      </DragDropContext>
    </div>
  );
}

const localizer = momentLocalizer(moment);
function CalendarView({openTask}){

  const [date, setDate] = useState(new Date());

  const onChange = newDate => {
    setDate(newDate);
  };

  const tasks= [
    {
      start: moment().toDate(),
      end: moment()
        .add(1, "days")
        .toDate(),
      title: "Some title"
    }
  ]

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={tasks}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 550 }}
      />
    </div>
  );
}

function SubTaskContainer({it, setSubTasks, index}){

  const { t } = useTranslation();
  const subTaskTitleRef = useRef(null);
  const subTaskStatusRef = useRef(null);
  const [isSubTaskStatusOpen, setIsSubTaskStatusOpen] = useState(false);

  useEffect(() => {
    if (subTaskTitleRef.current) {
      subTaskTitleRef.current.style.height = 'auto';
      subTaskTitleRef.current.style.height = subTaskTitleRef.current.scrollHeight + 'px';
    }
  }, [it.subTask]);

  const changeSubTaskStatus = (status) => {
    setSubTasks((prevSubTasks) =>
      prevSubTasks.map((task) =>
        task === it ? { ...task, status } : task
      )
    );
    setIsSubTaskStatusOpen(false);
  };
  const deleteSubTask = () => {
    setSubTasks((prevSubTasks) => prevSubTasks.filter((_, i) => i !== index));
  };

  const handleClickOutside = (event) => {
    if (subTaskStatusRef.current && !subTaskStatusRef.current.contains(event.target)) {
      setIsSubTaskStatusOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <div className='flex items-start justify-between border rounded-lg p-2.5 gap-2' >
      <textarea
        ref={subTaskTitleRef}
        placeholder={t("NEW_TASK_MODAL.SUB_TASK.PLACEHOLDER")}
        value={it.name}
        onChange={(e) => {
          setSubTasks((prevSubTasks) =>
            prevSubTasks.map((task) =>
              task === it ? { ...task, name: e.target.value } : task
            )
          );
        }}
        rows={1}
        className='resize-none overflow-hidden font-semibold w-full'
      />
      <div className='relative' >
        <div onClick={()=> setIsSubTaskStatusOpen(true)} className={cx(styles.status, {[styles.progress]: it.status === "En cours", [styles.finish]: it.status === "Terminé"})} >
          • {t(`COMMON.TASK_STATUS.${it.status}`)}
        </div>
        {
        isSubTaskStatusOpen &&
          <div className={styles.floatStatusMenu} style={{bottom: 0, top: 'auto', left: 'auto', right: 0}}
               ref={subTaskStatusRef}>
            <ul>
              <li className={styles.currStatus}>{it.status}</li>
              <li onClick={() => changeSubTaskStatus("UNSTARTED")} className={styles.status}>
                • Pas commencé
              </li>
              <li onClick={() => changeSubTaskStatus("IN_PROGRESS")} className={cx(styles.status, styles.progress)}>
                • En cours
              </li>
              <li onClick={() => changeSubTaskStatus("PAUSED")} className={cx(styles.status, styles.pause)}>
                • En pause
              </li>
              <li onClick={() => changeSubTaskStatus("FINISHED")} className={cx(styles.status, styles.finish)}>
                • Terminé
              </li>
            </ul>
          </div>
        }
      </div>
      <div className='p-1 border border-red-700 cursor-pointer rounded-full' onClick={() => deleteSubTask()}>
        <FiTrash className='text-red-700' size={14}/>
      </div>
    </div>
  )
}

export function TaskModal({close, task, projectId}) {

  // ClassicEditor.defaultConfig = config;
  const {t} = useTranslation();
  const taskTitleRef = useRef(null);
  const {openTaskChat} = useContext(UserContext);

  const [sprints, setSprints] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskInfo, setTaskInfo] = useState({
    assignedTo: "",
    status: "UNSTARTED",
    dueDate: null,
    priority: "LOW",
    estimatedTime: 1,
    sprintStatus: ""
  });
  const [body, setBody] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [subTasks, setSubTasks] = useState([]);

  const userMenuRef = useRef(null);
  const statusMenuRef = useRef(null);
  const dueDateMenuRef = useRef(null);
  const priorityMenuRef = useRef(null);
  const sprintMenuRef = useRef(null);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
  const [isDueDateMenuOpen, setIsDueDateMenuOpen] = useState(false);
  const [isPriorityMenuOpen, setIsPriorityMenuOpen] = useState(false);
  const [isSprintOpen, setIsSprintOpen] = useState(false);
  const [usersList, setUsersList] = useState([]);

  const changeUser = (newUser) =>{
    setTaskInfo({
      ...taskInfo,
      assignedTo: newUser
    });
    setIsUserMenuOpen(false);
  }
  const changeStatus = (newStatus)=>{
    setTaskInfo({
      ...taskInfo,
      status: newStatus
    });
    setIsStatusMenuOpen(false);
  }
  const changeDueDate = (newDate)=>{
    setTaskInfo({
      ...taskInfo,
      dueDate: newDate
    });
    setIsDueDateMenuOpen(false);
  }
  const changePriority = (newPriority)=>{
    setTaskInfo({
      ...taskInfo,
      priority: newPriority
    });
    setIsPriorityMenuOpen(false);
  }
  const changeEstimatedTime = (val)=>{
    setTaskInfo({
      ...taskInfo,
      estimatedTime: val
    });
  }
  const changeSprintStatus = (newStatus)=>{
    setTaskInfo({
      ...taskInfo,
      sprintStatus: newStatus
    });
    setIsSprintOpen(false);
  }

  const addSubTask = ()=>{
    setSubTasks((oldArr)=> [...oldArr, {name: "", status: "UNSTARTED"}] )
  }

  const submitTask = ()=>{
    const newTaskData = {
      name: taskTitle,
      projectId: (task.id) ? task.projectId : projectId,
      shouldStartAt: taskInfo.dueDate,
      assignee: taskInfo.assignedTo.id,
      status: taskInfo.status,
      priority: taskInfo.priority,
      estimation: taskInfo.estimatedTime,
      description: body,
      // taskType: ,
      sprintId: taskInfo.sprintStatus.id,
      subTasks: subTasks,
    }
    if(Object.keys(task).length > 0 && task.id ){
      updateTasks(newTaskData, task.id)
        .then((res)=> {
          close();
          console.log(res);
        })
        .catch((err)=> {console.log(err)});
    }else{
      createTasks(newTaskData)
        .then((res)=> {
          close();
          console.log(res);
        })
        .catch((err)=> {console.log(err)});
    }
  }

  const submitDeleteTask = ()=>{
    deleteTask(task.id)
      .then((res)=> close() )
      .catch((err)=> {console.log(err)});
  }

  const handleClickOutside = (event) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
      setIsUserMenuOpen(false);
    }
    if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
      setIsStatusMenuOpen(false);
    }
    if (dueDateMenuRef.current && !dueDateMenuRef.current.contains(event.target)) {
      setIsDueDateMenuOpen(false);
    }
    if (priorityMenuRef.current && !priorityMenuRef.current.contains(event.target)) {
      setIsPriorityMenuOpen(false);
    }
    if (sprintMenuRef.current && !sprintMenuRef.current.contains(event.target)) {
      setIsSprintOpen(false);
    }
  };

  const fetchSprints = async ()=>{
    const sprintData= {
      "keyWord": "",
      "projectId": projectId
    }
    await getAllSprint(sprintData)
      .then((res)=> {
        setSprints(res);
      })
      .catch((err)=> console.log(err) );
  }

  const fetchUsersList = async () => {
    getAllTenantUsers({keyWord: searchQuery})
      .then(users => { setUsersList(users);})
      .catch(err => console.log(err));
  }

  const getAssignedTo = async (id)=>{
    getUsersInfoByIds([id])
      .then((res) => {
        return (res);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(Object.keys(task).length === 0){
      if(sprints.length > 0 ){
        setTaskInfo({
          ...taskInfo,
          sprintStatus: sprints[0]
        });
      }
    }
  }, [sprints]);

  useEffect( () => {
    fetchSprints();
  }, []);

  useEffect(() => {
    fetchUsersList();
  }, [searchQuery]);

  useEffect(() => {
    if (taskTitleRef.current) {
      taskTitleRef.current.style.height = 'auto';
      taskTitleRef.current.style.height = taskTitleRef.current.scrollHeight + 'px';
    }
  }, [taskTitle]);

  const fetchCurrTask = async ()=>{
    const assignee = task.assignee && await getUsersInfoByIds([task.assignee]);
    setTaskTitle(task.name);
    setTaskInfo({
      ...taskInfo,
      assignedTo: task.assignee ? assignee[0] : "",
      status: task.status,
      dueDate: task.shouldStartAt ? new Date(task.shouldStartAt) : null,
      priority: task.priority,
      sprintStatus: task.sprint,
      estimatedTime: task.estimation,
    });
    task.description && setBody(task.description);
    setSubTasks(task.subTasks);
  }

  useEffect(()=>{
    if(Object.keys(task).length > 0){
      fetchCurrTask();
    }
  },[task]);

  return(
    <div className={styles.taskModal}>
      <div className='flex justify-end gap-2'>
        <button onClick={()=> submitDeleteTask() } className="hover:text-red-700 hover:bg-gray-100 p-1.5 rounded-lg" >
          <FiTrash size={20} color="#666" />
        </button>
        <button onClick={() => openTaskChat({})} className='cursor-pointer p-1.5 rounded-lg hover:bg-gray-100'>
          <FiMessageCircle size={20} color='#666'/>
        </button>
        {/* <button className='cursor-pointer p-1.5 rounded-lg hover:bg-gray-100' >
          <FiClock size={20} color='#666' />
        </button> */}
        <button className='cursor-pointer p-1.5 rounded-lg hover:bg-gray-100'>
          <FiBookmark size={20} color='#666'/>
        </button>
        <button className='cursor-pointer p-1.5 rounded-lg hover:bg-gray-100' onClick={() => close()}>
          <FiX size={20} color='#666'/>
        </button>
      </div>
      <div className='mt-4'>
        <textarea
          ref={taskTitleRef}
          placeholder={t("NEW_TASK_MODAL.TASK_NAME")}
          value={taskTitle}
          onChange={(e) => setTaskTitle(e.target.value)}
          rows={1}
          className='resize-none overflow-hidden text-2xl font-semibold w-full'
        />
      </div>
      <div className='mt-4 font-semibold text-gray-400 border-b pb-6'>
        <div className='grid grid-cols-3 w-full text-sm mb-2'>
          <div className='col-span-1 flex items-center gap-2'>
            <FiUsers/>
            {t("NEW_TASK_MODAL.ASSIGNED_TO.TITLE")}
          </div>
          <div className='relative col-span-2'>
            <div onClick={() => setIsUserMenuOpen(true)}
                 className='pl-4 text-[#131133] hover:bg-gray-100 rounded-lg py-2 col-span-2 cursor-pointer flex items-center gap-1.5'>
              {taskInfo.assignedTo.length === 0
                ? <span className='text-gray-400'>{t("NEW_TASK_MODAL.ASSIGNED_TO.PLACEHOLDER")}</span>
                : <>
                  <FiUser size={15}/> {taskInfo.assignedTo.firstName} {taskInfo.assignedTo.lastName}
                </>
              }
            </div>
            {
              isUserMenuOpen &&
              <div className={styles.floatStatusMenu} ref={userMenuRef}>
                <ul>
                  <li className={styles.searchField}>
                    <FiSearch/>
                    <input
                      type='text'
                      placeholder={t("NEW_TASK_MODAL.ASSIGNED_TO.SEARCH")}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </li>
                  {
                    usersList.map((it) =>
                      <li key={it.id} onClick={() => changeUser(it)}
                          className={styles.userSelect}>{it.firstName} {it.lastName}</li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='grid grid-cols-3 w-full text-sm mb-4'>
          <div className='col-span-1 flex items-center gap-2'>
            <FiLoader/>
            {t("COMMON.STATUS")}
          </div>
          <div className='relative'>
            <div
              onClick={() => setIsStatusMenuOpen(true)}
              className={cx(styles.status)}
              style={{color: EnumsColors[taskInfo.status].text, backgroundColor: EnumsColors[taskInfo.status].bg}}
            >
              • {t(`COMMON.TASK_STATUS.${taskInfo.status}`)}
            </div>
            {
              isStatusMenuOpen &&
              <div className={styles.floatStatusMenu} ref={statusMenuRef}>
                <ul>
                  <li className={styles.currStatus}>{t(`COMMON.TASK_STATUS.${taskInfo.status}`)}</li>
                  {
                    TaskStatus.map((it) =>
                      <li
                        key={it}
                        onClick={() => changeStatus(it)}
                        className={styles.status}
                        style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}
                      >
                        • {t(`COMMON.TASK_STATUS.${it}`)}
                      </li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='grid grid-cols-3 w-full text-sm mb-2'>
          <div className='col-span-1 flex items-center gap-2'>
            <FiArrowDownCircle/>
            {t("COMMON.PRIORITY")}
          </div>
          <div className='relative col-span-2'>
            {Object.keys(taskInfo.priority).length === 0
              ? <div onClick={() => setIsPriorityMenuOpen(true)}
                     className='text-gray-400 pl-4 hover:bg-gray-100 rounded-lg py-2 col-span-2 cursor-pointer w-full'>
                <span>Please choose a priority</span>
              </div>
              : <div
                onClick={() => setIsPriorityMenuOpen(true)}
                style={{color: EnumsColors[taskInfo.priority].text, backgroundColor: EnumsColors[taskInfo.priority].bg}}
                className={styles.priority}
              >
                {t(`COMMON.TASK_PRIORITY.${taskInfo.priority}`)}
              </div>
            }
            {
              isPriorityMenuOpen &&
              <div className={styles.floatStatusMenu} ref={priorityMenuRef}>
                <ul>
                  <li className={styles.currStatus}>{t(`COMMON.TASK_PRIORITY.${taskInfo.priority}`)}</li>
                  {
                    Priority.map((it) =>
                      <li
                        key={it}
                        onClick={() => changePriority(it)}
                        style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}
                        className={styles.priority}
                      >
                        {t(`COMMON.TASK_PRIORITY.${it}`)}
                      </li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='grid grid-cols-3 w-full text-sm mb-2'>
          <div className='col-span-1 flex items-center gap-2'>
            <FiCalendar/>
            {t("NEW_TASK_MODAL.DUE_DATE.TITLE")}
          </div>
          <div className='relative col-span-2'>
            <div onClick={() => setIsDueDateMenuOpen(true)}
                 className=' pl-4 text-[#131133] hover:bg-gray-100 rounded-lg py-2 col-span-2 cursor-pointer'>
              {taskInfo.dueDate === null
                ? <span className='text-gray-400'>{t("NEW_TASK_MODAL.DUE_DATE.PLACEHOLDER")}</span>
                : moment(taskInfo.dueDate).locale('fr').format('DD/MM/YYYY')
              }
            </div>
            {
              isDueDateMenuOpen &&
              <div className={styles.floatDatePickerMenu} ref={dueDateMenuRef}>
                <DatePicker
                  className='p-2 w-full'
                  dateFormat="dd/MM/yyyy"
                  locale="fr"
                  selected={taskInfo.dueDate}
                  placeholderText={t("NEW_TASK_MODAL.DUE_DATE.SEARCH")}
                  onChange={changeDueDate}
                />
              </div>
            }
          </div>
        </div>
        <div className='grid grid-cols-3 w-full text-sm mb-4'>
          <div className='col-span-1 flex items-center gap-2'>
            <FiClock/>
            {t("NEW_TASK_MODAL.ESTIMATED_TIME")}
          </div>
          <div className='relative col-span-2 pl-3'>
            <input
              placeholder={t("NEW_TASK_MODAL.ESTIMATED_TIME")}
              value={taskInfo.estimatedTime}
              onChange={(e) => changeEstimatedTime(e.target.value)}
              type='number'
              className='w-10 text-black mr-1'
              min={1}
            />
            <label>{t("COMMON.DAYS")}</label>
          </div>
        </div>
        <div className='grid grid-cols-3 w-full text-sm mb-2'>
          <div className='col-span-1 flex items-center gap-2'>
            <DiScrum size={22}/>
            {t("NEW_TASK_MODAL.SPRINT")}
          </div>
          <div className='relative'>
            <div onClick={() => setIsSprintOpen(true)} className={cx(styles.status)}>
              • {taskInfo.sprintStatus.name}
            </div>
            {
              isSprintOpen &&
              <div className={styles.floatStatusMenu} ref={sprintMenuRef}>
                <ul>
                  <li className={styles.currStatus}>{taskInfo.sprintStatus.name}</li>
                  {
                    sprints.map((it) =>
                      <li key={it.id} onClick={() => changeSprintStatus(it)} className={styles.status}>• {it.name}</li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
      </div>
      <div className='mt-4 z-0 relative'>
        <div className='flex items-center justify-between mb-3 text-gray-600'>
          <p className='font-semibold '>{t("NEW_TASK_MODAL.SUB_TASK.TITLE")}</p>
          <FiPlusCircle onClick={() => addSubTask()} size={20} className='cursor-pointer hover:text-purple-light'/>
        </div>
        <div className='flex flex-col gap-2'>
          {subTasks.map((it, index) => <SubTaskContainer key={it.index} it={it} index={index}
                                                         setSubTasks={setSubTasks}/>)}
        </div>
      </div>
      <div className='mt-4 z-0 relative'>
        <p className='font-semibold text-gray-600 mb-3'>{t("COMMON.DESCRIPTION")}</p>
        <CKEditor
          editor={ClassicEditor}
          onChange={(event, editor) => {
            const data = editor.getData();
            setBody(data)
          }}
        />
      </div>
      <div className="fixed bottom-4 right-4">
        <button onClick={() => submitTask()} className={styles.mainBtn}>
          {t('COMMON.SAVE')}
        </button>
      </div>
    </div>
  )
}

export default function Overview() {

  const navigate = useNavigate();
  const {id} = useParams();
  const filtersMenuRef = useRef(null);
  const { t, i18n } = useTranslation();
  const {name, wideSideBarValue} = useContext(UserContext);

  const [showType, setShowType] = useState({id: 1, name: "sprint"});
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isStatusFiltersMenuOpen, setIsStatusFiltersMenuOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);
  const [isPriorityFiltersMenuOpen, setIsPriorityFiltersMenuOpen] = useState(false);
  const [filterPriority, setFilterPriority] = useState([]);
  const [sprints, setSprints] = useState([]);
  const [newFetch, setNewFetch] = useState(null);

  const [isNewSprintModalOpen, setIsNewSprintModalOpen] = useState(false);

  const openTaskModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsTaskModalOpen(true);
    setTaskData(task);
  }
  const closeTaskModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTaskModalOpen(false);
    setTaskData({});
    setNewFetch(new Date().getTime());
  }

  const openNewSprintModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewSprintModalOpen(true);
  }
  const closeNewSprintModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewSprintModalOpen(false);
    setNewFetch(new Date().getTime());
  }

  const toggleCheckStatus = (item)=>{
    if(filterStatus.some(it => it.id === item.id)){
      setFilterStatus(filterStatus.filter(it=> it.id !== item.id ));
    }else{
      setFilterStatus(oldArray => [...oldArray, item]);
    }
  }

  const toggleCheckPriority = (item)=>{
    if(filterPriority.some(it => it.id === item.id)){
      setFilterPriority(filterPriority.filter(it=> it.id !== item.id ));
    }else{
      setFilterPriority(oldArray => [...oldArray, item]);
    }
  }

  const fetchSprints = async ()=>{
    const sprintData= {
      "keyWord": "",
      "projectId": id
    }
    await getAllSprint(sprintData)
      .then((res)=> {
        setSprints(res);
      })
      .catch((err)=> console.log(err) );
  }

  useEffect(() => {
    fetchSprints();
  }, [newFetch]);

  const getCurrView = ()=>{
    let arr = [
      <TableView openTask={openTaskModal} projectId={id} newFetch={newFetch} />,
      <SprintView projectId={id} newFetch={newFetch} />,
      <KanbanView openTask={openTaskModal} projectId={id} newFetch={newFetch} />,
      <TasksTimeView projectId={id} />,
      // <CalendarView openTask={openTaskModal}/>,
    ]
    return arr[showType.id]
  }

  const handleClickOutside = (event) => {
    if (filtersMenuRef.current && !filtersMenuRef.current.contains(event.target)) {
      setIsFilterMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.home} >
      <div className={styles.head} >
        <Row style={{gap: '12px'}} >
          <div onClick={()=> navigate(-1) } className='border p-1.5 rounded-full cursor-pointer hover:text-purple-dark hover:border-purple-dark' >
            <FiChevronLeft/>
          </div>
          <h2>{t('PROJECT.TITLE')}</h2>
        </Row>
        <Row style={{gap: '12px'}}>
          <div className='relative'>
            <button onClick={() => setIsFilterMenuOpen(true)} className={styles.subBtn}>
              {t("COMMON.FILTER")}
            </button>
            {
              isFilterMenuOpen &&
              <div ref={filtersMenuRef} className={styles.floatMenuWrapper}
                   onMouseLeave={() => setIsFilterMenuOpen(false)}>
                <ul>
                  <li className={styles.floatMenuItem} onMouseEnter={() => setIsStatusFiltersMenuOpen(true)}
                      onMouseLeave={() => setIsStatusFiltersMenuOpen(false)}>
                    {t("COMMON.STATUS")}
                    {isStatusFiltersMenuOpen &&
                      <div className={styles.subFiltersMenuWrapper}>
                        <ul className={styles.statusWrapper}>
                          {status.map((it) =>
                            <li key={it.id}>
                              <Row>
                                <CheckBox isChecked={filterStatus.some(item => item.id === it.id)}
                                          toggleCheck={() => toggleCheckStatus(it)}/>
                                <div className={cx((styles.status), {
                                  [styles.progress]: it.id === "2",
                                  [styles.finish]: it.id === "3"
                                })}>
                                  {it.title}
                                </div>
                              </Row>
                            </li>
                          )}
                        </ul>
                      </div>
                    }
                  </li>
                  <li className={styles.floatMenuItem} onMouseEnter={() => setIsPriorityFiltersMenuOpen(true)}
                      onMouseLeave={() => setIsPriorityFiltersMenuOpen(false)}>
                    {t("COMMON.PRIORITY")}
                    {isPriorityFiltersMenuOpen &&
                      <div className={styles.subFiltersMenuWrapper}>
                        <ul className={styles.statusWrapper}>
                          {priorities.map((it) =>
                            <li key={it.id}>
                              <Row>
                                <CheckBox isChecked={filterPriority.some(item => item.id === it.id)}
                                          toggleCheck={() => toggleCheckPriority(it)}/>
                                <div className={cx((styles.status), {
                                  [styles.medium]: it.id === "2",
                                  [styles.hight]: it.id === "3"
                                })}>
                                  {it.title}
                                </div>
                              </Row>
                            </li>
                          )}
                        </ul>
                      </div>
                    }
                  </li>
                </ul>
              </div>
            }
          </div>
          <div className={styles.showType}>
            <button onClick={() => setShowType({id: 0, name: "table"})}
                    className={cx('flex items-center border-r px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-lg', {'bg-[#edecf9]': showType.id === 0})}>
              <LuTable size={23}/>
            </button>
            <button onClick={() => setShowType({id: 1, name: "sprint"})}
                    className={cx('flex items-center border-r px-1 py-1 cursor-pointer hover:bg-gray-100 rounded-lg', {'bg-[#edecf9]': showType.id === 1})}>
              <DiScrum size={32}/>
            </button>
            <button onClick={() => setShowType({id: 2, name: "kanban"})}
                    className={cx('flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-lg', {'bg-[#edecf9]': showType.id === 2})}>
              <TbColumns3 size={23}/>
            </button>
            <button onClick={() => setShowType({id: 3, name: "calendar"})}
                    className={cx('flex items-center border-r px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-lg', {'bg-[#edecf9]': showType.id === 3})}>
              <FiCalendar size={22}/>
            </button>
          </div>
          { sprints.length > 0 &&
            <button className={styles.mainBtn} onClick={() => openTaskModal({})}>
              {t('PROJECT.NEW_BTN')}
            </button>
          }
          {/*{showType.id === 1 &&*/}

          {/*}*/}
          <button
            className="border border-purple-light text-purple-light text-sm font-semibold px-[26px] py-[10px] rounded-lg"
            onClick={() => openNewSprintModal()}>
            {t('PROJECT.NEW_SPRINT')}
          </button>
        </Row>
      </div>
      <div className='mt-6'>
        {getCurrView()}
      </div>
      <AnimatePresence>
        {
          isTaskModalOpen &&
          <SideModal isOpen={isTaskModalOpen} title="side modal" close={closeTaskModal} >
            <TaskModal close={closeTaskModal} task={taskData} projectId={id} />
          </SideModal>
        }
      </AnimatePresence>
      <Modal isOpen={isNewSprintModalOpen} title="Nouveau Sprint" close={closeNewSprintModal} >
        <NewSprintModal close={closeNewSprintModal} projectId={id} />
      </Modal>
    </div>
  )
}
