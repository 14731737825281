import React, {useEffect, useState, useRef} from 'react'
import styles from '../Styles/myTasks.module.scss';
import { FiChevronRight, FiClock, FiEdit2, FiFolder, FiUser } from 'react-icons/fi';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import SideModal from './SideModal';
import { TaskModal } from './Overview';
import moment from 'moment';
import { AnimatePresence } from 'framer-motion';

const tasksTest = [
  { id: 1, task: 'Rédiger une proposition de projet', assignedTo: 'Adil Stifi', dueDate: '08/01/2024', status: "Pas commencé", priority: "Hight", subTasks: [] },
  { id: 2, task: 'Planifier la réunion de lancement', assignedTo: 'Adil Stifi', dueDate: '03/01/2024', status: "En cours", priority: "Medium", subTasks: [] },
];

export function MyPerformanceTaskContainer({task, openTaskModal}){

  return(
    <div onClick={()=> openTaskModal(task)  } className={styles.task}>
      <div className='flex items-center justify-between gap-1.5 text-xs text-gray-500 mb-3' >
        <div className='flex items-center gap-1.5 text-xs' >
          <FiFolder size={12}  />
          <p>Project name</p>
        </div>
        <div className={cx('flex items-center gap-1.5 text-xs', {'text-[#D6323A]': moment(new Date() ,'DD/MM/YYYY') > moment(task.dueDate, 'DD/MM/YYYY') })} >
          <FiClock size={12} />
          <span >{task.dueDate}</span>
        </div>
      </div>
      <p className='font-semibold mb-4' >{task.task}</p>
      <div className='flex flex-col gap-2 text-gray-500' > 
        <div className={cx((styles.priority), {[styles.medium]: task.priority === "Medium", [styles.hight]: task.priority === "Hight"})} >
          {task.priority}
        </div>
        <div className='relative w-max' >
          <button className={cx(styles.status, {[styles.progress]: task.status === "En cours", [styles.finish]: task.status === "Terminé"})} >
            {task.status}
          </button>
        </div>
      </div>
    </div>
  )
}

export default function MyPerformance({setIsRightBarOpen}) {

  const { t } = useTranslation();
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [taskData, setTaskData] = useState({});

  const openTaskModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsTaskModalOpen(true);
    setTaskData(task);
  }
  const closeTaskModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTaskModalOpen(false);
    setTaskData({});
  }

  return (
    <div className='border rounded-xl bg-white p-2 w-[18%]' >
      <div className='flex items-center justify-between' >
        <p className='text-gray-500 text-xs font-semibold' >{t('ROOT.RIGHT_BAR.TITLE')}</p>
        <FiChevronRight onClick={()=> setIsRightBarOpen(false) } size={16} className='text-gray-500 cursor-pointer' />
      </div>
      <div className='flex flex-col items-center mt-3 justify-center w-full' >
        <div className='border p-1 rounded-full w-max mb-3' >
          <FiUser size={48} />
        </div>
        <p className='font-semibold mb-1' >Adil Stifi</p>
        <p className='text-gray-500 text-sm' >Front-end Developer</p>
      </div>
      <div className='flex items-center justify-between mt-5' >
        <div className='w-14 h-max' >
          <div className='border-[#23B301] border-2 w-full h-14 flex items-center justify-center rounded-full' >
            <span className='text-lg font-bold text-[#23B301]' >18</span>
            <span className='text-gray-400 font-bold text-xs' >/ 30</span>
          </div>
          <p className='text-xs font-semibold text-[#23B301] text-center mt-2' >{t('ROOT.RIGHT_BAR.PERFORMANCE_STATUS.COMPLETED')}</p>
        </div>
        <div className='w-14 h-max' >
          <div className='border-purple-dark border-2 w-full h-14 flex items-center justify-center rounded-full' >
            <span className='text-lg font-bold text-purple-dark' >10</span>
            <span className='text-gray-400 font-bold text-xs' >/ 30</span>
          </div>
          <p className='text-xs font-semibold text-purple-dark text-center mt-2' >{t('ROOT.RIGHT_BAR.PERFORMANCE_STATUS.PROGRESS')}</p>
        </div>
        <div className='w-14 h-max' >
          <div className='border-[#D6323A] border-2 w-full h-14 flex items-center justify-center rounded-full' >
            <span className='text-lg font-bold text-[#D6323A]' >2</span>
            <span className='text-gray-400 font-bold text-xs' >/ 30</span>
          </div>
          <p className='text-xs font-semibold text-[#D6323A] text-center mt-2' >{t('ROOT.RIGHT_BAR.PERFORMANCE_STATUS.DELAYED')}</p>
        </div>
      </div>
      <div className='rounded-lg mt-4 flex flex-col gap-2' >
        {tasksTest.map((task) => (
          <MyPerformanceTaskContainer key={task.id} task={task} openTaskModal={openTaskModal} />
        ))}
      </div>
      <AnimatePresence>
        {
          isTaskModalOpen &&
          <SideModal isOpen={isTaskModalOpen} title="side modal" close={closeTaskModal} >
            <TaskModal close={closeTaskModal} task={taskData} />
          </SideModal>
        }
      </AnimatePresence>
    </div>
  )
}
