import React, {useContext, useRef, useState, useEffect} from 'react';
import styles from '../Styles/overview.module.scss';
import { useTranslation } from 'react-i18next';
import { FiCalendar, FiSearch } from 'react-icons/fi';
import { LuTable } from "react-icons/lu";
import { TbColumns3 } from "react-icons/tb";
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';

import { UserContext } from '../Contexts/UserContext';
import { CheckBox, Row } from '../Styles/DefaultComponents';
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/fr';
import SideModal from './SideModal';
import TasksTimeView from './TasksTimeView';
import { AnimatePresence } from 'framer-motion';
import { KanbanView, TableView, TaskModal } from './Overview';
import {getAllSprint} from "../services/sprintService";

const status = [
  {id: "1", title: "Pas commencé", bgColor: "rgba(0, 0, 0, 0.1)"},
  {id: "2", title: "En cours", bgColor: "#D3E5EF"},
  {id: "3", title: "Terminé", bgColor: "#DBEDDB"},
];

const priorities = [
  {id: "1", title: "Low", bgColor: "rgba(0, 0, 0, 0.1)"},
  {id: "2", title: "Medium", bgColor: "#FEEBC8"},
  {id: "3", title: "High", bgColor: "#FFE2DD"},
];

export default function Tasks() {

  const navigate = useNavigate();
  const filtersMenuRef = useRef(null);
  const { t, i18n } = useTranslation();
  const {name, wideSideBarValue} = useContext(UserContext);
  const [showType, setShowType] = useState({id: 1, name: "kanban"});
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isStatusFiltersMenuOpen, setIsStatusFiltersMenuOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);
  const [isPriorityFiltersMenuOpen, setIsPriorityFiltersMenuOpen] = useState(false);
  const [filterPriority, setFilterPriority] = useState([]);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const [sprints, setSprints] = useState([]);

  const openTaskModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsTaskModalOpen(true);
    setTaskData(task);
  }
  const closeTaskModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTaskModalOpen(false);
    setTaskData({});
  }

  const toggleCheckStatus = (item)=>{
    if(filterStatus.some(it => it.id === item.id)){
      setFilterStatus(filterStatus.filter(it=> it.id !== item.id ));
    }else{
      setFilterStatus(oldArray => [...oldArray, item]);
    }
  }

  const toggleCheckPriority = (item)=>{
    if(filterPriority.some(it => it.id === item.id)){
      setFilterPriority(filterPriority.filter(it=> it.id !== item.id ));
    }else{
      setFilterPriority(oldArray => [...oldArray, item]);
    }
  }

  const getCurrView = ()=>{
    let arr = [
      <TableView openTask={openTaskModal} />,
      <KanbanView openTask={openTaskModal}/>,
      <TasksTimeView/>,
    ]
    return arr[showType.id]
  }

  const handleClickOutside = (event) => {
    if (filtersMenuRef.current && !filtersMenuRef.current.contains(event.target)) {
      setIsFilterMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.head}>
        <h2>{t("ALL_TASKS.TITLE")}</h2>
        <Row style={{ gap: "12px" }}>
          <div className="relative">
            <button onClick={() => setIsFilterMenuOpen(true)} className={styles.subBtn} >
              {t("COMMON.FILTER")}
            </button>
            {isFilterMenuOpen && (
              <div
                ref={filtersMenuRef}
                className={styles.floatMenuWrapper}
                onMouseLeave={() => setIsFilterMenuOpen(false)}
              >
                <ul>
                  <li
                    className={styles.floatMenuItem}
                    onMouseEnter={() => setIsStatusFiltersMenuOpen(true)}
                    onMouseLeave={() => setIsStatusFiltersMenuOpen(false)}
                  >
                    {t("COMMON.STATUS")}
                    {isStatusFiltersMenuOpen && (
                      <div className={styles.subFiltersMenuWrapper}>
                        <ul className={styles.statusWrapper}>
                          {status.map((it) => (
                            <li key={it.id}>
                              <Row>
                                <CheckBox
                                  isChecked={filterStatus.some(
                                    (item) => item.id === it.id
                                  )}
                                  toggleCheck={() => toggleCheckStatus(it)}
                                />
                                <div
                                  className={cx(styles.status, {
                                    [styles.progress]: it.id === "2",
                                    [styles.finish]: it.id === "3",
                                  })}
                                >
                                  {it.title}
                                </div>
                              </Row>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                  <li
                    className={styles.floatMenuItem}
                    onMouseEnter={() => setIsPriorityFiltersMenuOpen(true)}
                    onMouseLeave={() => setIsPriorityFiltersMenuOpen(false)}
                  >
                    {t("COMMON.PRIORITY")}
                    {isPriorityFiltersMenuOpen && (
                      <div className={styles.subFiltersMenuWrapper}>
                        <ul className={styles.statusWrapper}>
                          {priorities.map((it) => (
                            <li key={it.id}>
                              <Row>
                                <CheckBox
                                  isChecked={filterPriority.some(
                                    (item) => item.id === it.id
                                  )}
                                  toggleCheck={() => toggleCheckPriority(it)}
                                />
                                <div
                                  className={cx(styles.status, {
                                    [styles.medium]: it.id === "2",
                                    [styles.hight]: it.id === "3",
                                  })}
                                >
                                  {it.title}
                                </div>
                              </Row>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            )}
          </div>
          <motion.div
            initial={{ width: 'auto' }}
            animate={{ width: isSearchInputFocused ? 400 : 'auto' }}
            transition={{ duration: 0.3 }}
            className="border py-[10px] px-2 rounded-lg flex items-center justify-between"
          >
            <input
              placeholder={t("ALL_PROJECTS.SEARCH_PLACEHOLD")}
              className="text-sm"
              onFocus={() => setIsSearchInputFocused(true)}
              onBlur={() => setIsSearchInputFocused(false)}
            />
            <FiSearch size={16} className="text-gray-400" />
          </motion.div>
          <div className={styles.showType}>
            <button
              onClick={() => setShowType({ id: 0, name: "table" })}
              className={cx(
                "flex items-center border-r px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-lg",
                { "bg-[#edecf9]": showType.id === 0 }
              )}
            >
              <LuTable size={23} />
            </button>
            <button
              onClick={() => setShowType({ id: 1, name: "kanban" })}
              className={cx(
                "flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-lg",
                { "bg-[#edecf9]": showType.id === 1 }
              )}
            >
              <TbColumns3 size={23} />
            </button>
            <button
              onClick={() => setShowType({ id: 2, name: "calendar" })}
              className={cx(
                "flex items-center border-r px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-lg",
                { "bg-[#edecf9]": showType.id === 2 }
              )}
            >
              <FiCalendar size={22} />
            </button>
          </div>
          <button className={styles.mainBtn} onClick={() => openTaskModal({})}>
            {t("PROJECT.NEW_BTN")}
          </button>
        </Row>
      </div>
      <div className="mt-6">{getCurrView()}</div>
      <AnimatePresence>
        {isTaskModalOpen && (
          <SideModal
            isOpen={isTaskModalOpen}
            title="side modal"
            close={closeTaskModal}
          >
            <TaskModal close={closeTaskModal} task={taskData} />
          </SideModal>
        )}
      </AnimatePresence>
    </div>
  );
}
