import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from '../Styles/allProjects.module.scss';
import {useNavigate} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {UserContext} from '../Contexts/UserContext';
import {CheckBox, ProgressBar, Row} from '../Styles/DefaultComponents';
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
  FiEdit,
  FiPlus,
  FiSearch,
  FiTrash,
  FiUser
} from 'react-icons/fi';
import Modal from './Modal';
import {getAllUsers, getUsersInfoByIds} from "../services/usersService";
import {
  createProject, deleteProject,
  getAllProjectStatus,
  getMineProjects, getProjectById,
  getProjectsPage,
  updateProject
} from "../services/projectService";
import moment from "moment";
import {EnumsColors, ProjectStatus} from "../Ressources/enums";

const dataTest = [
  {id: 1, name: "AMEE", status: "Paused", numOfTasks: 30},
  {id: 2, name: "AUBB", status: "Finished", numOfTasks: 25},
  {id: 3, name: "Culture", status: "Paused", numOfTasks: 20},
  {id: 4, name: "Skolistic", status: "In progress", numOfTasks: 15},
  {id: 5, name: "ACAPS", status: "Not started", numOfTasks: 15},
]

function KanbanView({}) {

  const {t} = useTranslation();
  const [columnOrder, setColumnOrder] = useState([]);
  const [draggingSprintName, setDraggingSprintName] = useState(null);
  const [sprintName, setSprintName] = useState("");

  const addSprint = (name) => {
    if (!columnOrder.includes(name) && name) {
      setColumnOrder([...columnOrder, name]);
    }
  };

  const removeSprint = (name) => {
    setColumnOrder(columnOrder.filter((it) => it !== name));
  }

  const handleDragStart = (sprintName) => {
    setDraggingSprintName(sprintName);
  };

  const handleDragOver = (index) => (e) => {
    e.preventDefault();
    const draggingIndex = columnOrder.indexOf(draggingSprintName);
    if (draggingIndex !== index) {
      const newColumnOrder = Array.from(columnOrder);
      newColumnOrder.splice(draggingIndex, 1);
      newColumnOrder.splice(index, 0, draggingSprintName);
      setColumnOrder(newColumnOrder);
    }
  };

  const handleDrop = () => {
    setDraggingSprintName(null);
  };

  return (
    <div>
      <div className='flex items-center justify-between gap-3 mb-3'>
        <div className='border py-2 px-3 h-10 w-full flex items-center justify-between rounded-lg'>
          <input
            placeholder={t("ALL_PROJECTS.ADD_MODAL.SPRINT.PLACEHOLDER")}
            className='text-sm w-full'
            value={sprintName}
            onChange={(e) => setSprintName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.target.value.trim()) {
                addSprint(e.target.value.trim());
                setSprintName("");
              }
            }}
          />
        </div>
        <button onClick={() => {
          addSprint(sprintName.trim());
          setSprintName("")
        }}
                className='group border py-2 px-3 h-10 flex items-center justify-between rounded-lg hover:border-purple-light'>
          <FiPlus size={18} className='text-gray-500 group-hover:text-purple-light'/>
        </button>
      </div>
      {
        columnOrder.length > 0 &&
        <div className='flex overflow-auto gap-3 p-2 pb-6'>
          {columnOrder.map((sprintName, index) => (
            <div
              key={sprintName}
              draggable
              onDragStart={() => handleDragStart(sprintName)}
              onDragOver={handleDragOver(index)}
              onDrop={handleDrop}
              className='border p-2 rounded-lg min-h-[60px] w-max flex items-center justify-center text-sm relative'
              style={{
                userSelect: 'none',
                boxShadow: draggingSprintName === sprintName ? '0 4px 6px rgba(0, 0, 0, 0.3)' : 'none',
                transition: 'box-shadow 0.2s',
                cursor: "grab",
                minWidth: '200px'
              }}
            >
              {sprintName}
              <FiTrash size={16} className='absolute top-2 right-2 text-red-700 cursor-pointer bg-white'
                       onClick={(e) => {
                         e.stopPropagation();
                         removeSprint(sprintName)
                       }}/>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

function NewProjectModal({close}) {

  const {t} = useTranslation();
  const statusSelectRef = useRef(null);

  const [name, setName] = useState("");
  const [deadline, setDeadLine] = useState(null);
  const [userSearchInput, setUserSearchInput] = useState("");
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [team, setTeam] = useState([]);
  const [status, setStatus] = useState(ProjectStatus[0]);
  const [isStatusSelectOpen, setIsStatusSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectUser = (user) => {
    if (!team.map((it) => it.id).includes(user.id)) {
      setTeam(oldArray => [...oldArray, user]);
      setUserSearchInput("");
    }
  }
  const removeUser = (user) => {
    setTeam(team.filter(it => it.id !== user.id));
  }

  const clickStatus = (it) => {
    setIsStatusSelectOpen(false);
    setStatus(it);
  }

  const submit = () => {
    setLoading(true);
    const projectData = {
      "name": name,
      "deadline": deadline,
      "members": team.map((it) => it.id),
      "status": status
    }
    createProject(projectData)
      .then((res) => {
        console.log(res);
        close();
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  const fetchUsers = () => {
    getAllUsers({keyWord: userSearchInput})
      .then((res) => setAllUsers(res))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (userSearchInput.length > 0) {
      fetchUsers();
    }
  }, [userSearchInput]);

  useEffect(() => {
    if (userSearchInput.length > 0) {
      setIsUserSelectOpen(true);
    } else {
      setIsUserSelectOpen(false);
    }
  }, [userSearchInput]);

  const handleClickOutside = (event) => {
    if (statusSelectRef.current && !statusSelectRef.current.contains(event.target)) {
      setIsStatusSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='min-h-[50vh] flex flex-col justify-between'>
      <div>
        <div className='mb-2'>
          <div className='mb-0.5 ml-3'>
            <label className='text-xs font-semibold text-gray-500'>{t("ALL_PROJECTS.ADD_MODAL.NAME")}</label>
          </div>
          <input
            placeholder={t("ALL_PROJECTS.ADD_MODAL.NAME")}
            className='text-sm border py-2 px-3 h-10 rounded-lg w-full'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='grid grid-cols-3 gap-5 mb-5'>
          <div className="w-full col-span-2">
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>{t("ALL_PROJECTS.ADD_MODAL.DEADLINE")}</label>
            </div>
            <DatePicker
              className='py-[9px] px-3 text-sm w-full border rounded-lg'
              dateFormat="dd/MM/yyyy"
              locale="fr"
              isClearable={true}
              placeholderText={t("ALL_PROJECTS.ADD_MODAL.DEADLINE")}
              wrapperClassName="w-full"
              selected={deadline}
              onChange={(e) => setDeadLine(e)}
            />
          </div>
          <div className="relative flex flex-col justify-between">
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>{t("COMMON.STATUS")}</label>
            </div>
            <div className='relative min-h-10 flex items-center'>
              <button
                onClick={() => setIsStatusSelectOpen(true)}
                className={cx(styles.status)}
                style={{color: EnumsColors[status].text, backgroundColor: EnumsColors[status].bg}}
              >
                • {t(`COMMON.PROJECT_STATUS.${status}`)}
              </button>
              {
                isStatusSelectOpen &&
                <div className={styles.floatStatusMenu} ref={statusSelectRef}>
                  <ul>
                    <li onClick={() => setIsStatusSelectOpen(false)} className={styles.currStatus}>
                      {t(`COMMON.PROJECT_STATUS.${status}`)}
                    </li>
                    {
                      ProjectStatus.map((it) =>
                        <li
                          key={it}
                          onClick={() => clickStatus(it)}
                          className={styles.status}
                          style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}
                        >
                          • {t(`COMMON.PROJECT_STATUS.${it}`)}
                        </li>
                      )
                    }
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>

        {/*<div className='border rounded-lg p-2 pt-4 relative mb-4'>*/}
        {/*  <div className='absolute top-[-10px] left-4 px-2 bg-white text-xs font-semibold text-gray-500'>*/}
        {/*    {t("ALL_PROJECTS.ADD_MODAL.SPRINT.TITLE")}*/}
        {/*  </div>*/}

        {/*  <KanbanView/>*/}
        {/*</div>*/}
        <div className='border rounded-lg p-2 pt-4 relative'>
          <div className='absolute top-[-10px] left-4 px-2 bg-white text-xs font-semibold text-gray-500'>
            {t("ALL_PROJECTS.ADD_MODAL.TEAM.TITLE")}
          </div>
          <div className='border py-2 px-3 h-10 flex items-center justify-between rounded-lg gap-2'>
            <input
              placeholder={t("USERS.SEARCH_PLACEHOLD")}
              className='text-sm w-full'
              value={userSearchInput}
              onChange={(e) => setUserSearchInput(e.target.value)}
            />
            <FiSearch size={16} className='text-gray-400'/>
          </div>
          {
          isUserSelectOpen &&
            <div
              className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[3rem]'>
              <ul className='flex flex-col gap-2'>
                {
                  allUsers.map((it) =>
                    <li key={it.id} onClick={() => selectUser(it)}
                        className={cx('p-1 px-2 rounded-lg flex items-center gap-3 cursor-pointer hover:bg-purple-100 hover:text-black', {'bg-purple-light text-white': team.includes(it)})}>
                      <div className='border p-1 rounded-full'>
                        <FiUser size={18}/>
                      </div>
                      <p className='font-semibold'>{it.firstName} {it.lastName}</p>
                    </li>
                  )
                }
              </ul>
            </div>
          }
          {team.length > 0 &&
            team.map((it) =>
              <div key={it.id} className='flex flex-col gap-3 mt-4'>
                <div className={styles.projectTeam_usersWrapper}>
                  <div className='flex items-center gap-3'>
                    <div className='border p-1 rounded-full'>
                      <FiUser size={28}/>
                    </div>
                    <div>
                      <p className='text-sm font-semibold'>{it.firstName} {it.lastName}</p>
                      {/*<p className='text-xs font-semibold text-gray-500'>full stack developer</p>*/}
                    </div>
                  </div>
                  <FiTrash onClick={() => removeUser(it)} className='text-red-700 cursor-pointer' size={16}/>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className='mt-6 flex items-center justify-end min-w-[40vw]'>
        {!loading &&
          <button onClick={() => submit()} className={styles.mainBtn}>
            Save project
          </button>
        }
        {loading &&
          <div className="flex items-center justify-center px-4 w-28 h-10 bg-purple-light rounded-lg">
            <div className="loader"/>
          </div>
        }
      </div>
    </div>
  )
}

function EditProjectModalV2({close, oldData}) {

  const {t} = useTranslation();
  const statusSelectRef = useRef(null);

  const [name, setName] = useState("");
  const [deadline, setDeadLine] = useState(null);
  const [userSearchInput, setUserSearchInput] = useState("");
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [team, setTeam] = useState([]);
  const [status, setStatus] = useState(ProjectStatus[0]);
  const [isStatusSelectOpen, setIsStatusSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});

  const selectUser = (user) => {
    if (!team.map((it) => it.id).includes(user.id)) {
      setTeam(oldArray => [...oldArray, user]);
      setUserSearchInput("");
    }
  }
  const removeUser = (user) => {
    setTeam(team.filter(it => it.id !== user.id));
  }

  const clickStatus = (it) => {
    setIsStatusSelectOpen(false);
    setStatus(it);
  }

  const submitDeleteProject = ()=>{
    deleteProject(oldData.id)
      .then((res) => {
        console.log(res);
        close();
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  const submit = () => {
    setLoading(true);
    const projectData = {
      "name": name,
      "deadline": deadline,
      "members": team.map((it) => it.id),
      "status": status
    }
    updateProject(projectData, oldData.id)
      .then((res) => {
        console.log(res);
        close();
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  const fetchUsers = () => {
    getAllUsers({keyWord: userSearchInput})
      .then((res) => setAllUsers(res))
      .catch((err) => console.log(err));
  }

  const fetchProjectData = async () => {
    getProjectById(oldData.id)
      .then((res) => {
        setProjectInfo(res);
        console.log(res);
      })
      .catch((err) => console.log(err));
  }

  const getProjectMembers = async (members)=>{
    getUsersInfoByIds(members)
      .then((res) => {
        setTeam(res);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (userSearchInput.length > 0) {
      fetchUsers();
    }
  }, [userSearchInput]);

  useEffect(() => {
    if (userSearchInput.length > 0) {
      setIsUserSelectOpen(true);
    } else {
      setIsUserSelectOpen(false);
    }
  }, [userSearchInput]);

  useEffect(() => {
    if (Object.keys(projectInfo).length > 0) {
      setName(projectInfo.name);
      setStatus(projectInfo.status);
      setDeadLine(new Date(projectInfo.deadline));
      // projectInfo.members && setTeam(projectInfo.members);
      projectInfo.members && getProjectMembers(projectInfo.members);
    }
  }, [projectInfo]);

  useEffect(() => {
    if (Object.keys(oldData).length > 0) {
      fetchProjectData();
    }
  }, [oldData]);

  return (
    <div className='min-h-[50vh] flex flex-col justify-between'>
      <div>
        <div className='mb-4'>
          <input
            placeholder={t("ALL_PROJECTS.ADD_MODAL.NAME")}
            className='text-sm border py-2 px-3 h-10 rounded-lg w-full'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='grid grid-cols-2 gap-2 mb-5'>
          {/*<div className="relative min-h-10" >*/}
          {/*  <select onChange={handleStatusChange} className={cx("appearance-none w-full border h-full rounded-lg px-3 text-sm relative",{"text-gray-400": status.name === "Not started"})} >*/}
          {/*    {*/}
          {/*      statusList.map((it)=>*/}
          {/*      <option value={it.id} key={it.id} >*/}
          {/*        {it.name}*/}
          {/*      </option>)*/}
          {/*    }*/}
          {/*  </select>*/}
          {/*  <FiChevronDown size={20} className='absolute top-2.5 right-3 text-gray-400' />*/}
          {/*</div>*/}
          <div className="relative min-h-10 w-full border h-full rounded-lg px-3 py-1">
            <div className="flex items-center gap-2">
              <label className="text-sm font-semibold text-gray-400">
                {t("COMMON.STATUS")} :
              </label>
              <div className='relative'>
                <button
                  onClick={() => setIsStatusSelectOpen(true)}
                  className={cx(styles.status)}
                  style={{color: EnumsColors[status].text, backgroundColor: EnumsColors[status].bg}}
                >
                  • {t(`COMMON.PROJECT_STATUS.${status}`)}
                </button>
                {
                  isStatusSelectOpen &&
                  <div className={styles.floatStatusMenu} ref={statusSelectRef}>
                    <ul>
                      <li onClick={() => setIsStatusSelectOpen(false)} className={styles.currStatus}>
                        {t(`COMMON.PROJECT_STATUS.${status}`)}
                      </li>
                      {
                        ProjectStatus.map((it) =>
                          <li
                            key={it}
                            onClick={() => clickStatus(it)}
                            className={styles.status}
                            style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}
                          >
                            • {t(`COMMON.PROJECT_STATUS.${it}`)}
                          </li>
                        )
                      }
                    </ul>
                  </div>
                }
              </div>
            </div>
          </div>
          <DatePicker
            className='py-[9px] px-3 text-sm w-full border rounded-lg'
            dateFormat="dd/MM/yyyy"
            locale="fr"
            isClearable={true}
            placeholderText={t("ALL_PROJECTS.ADD_MODAL.DEADLINE")}
            selected={deadline}
            onChange={(e) => setDeadLine(e)}
          />
        </div>

        {/*<div className='border rounded-lg p-2 pt-4 relative mb-4'>*/}
        {/*  <div className='absolute top-[-10px] left-4 px-2 bg-white text-xs font-semibold text-gray-500'>*/}
        {/*    {t("ALL_PROJECTS.ADD_MODAL.SPRINT.TITLE")}*/}
        {/*  </div>*/}

        {/*  <KanbanView/>*/}
        {/*</div>*/}
        <div className='border rounded-lg p-2 pt-4 relative'>
          <div className='absolute top-[-10px] left-4 px-2 bg-white text-xs font-semibold text-gray-500'>
            {t("ALL_PROJECTS.ADD_MODAL.TEAM.TITLE")}
          </div>
          <div className='border py-2 px-3 h-10 flex items-center justify-between rounded-lg gap-2'>
            <input
              placeholder={t("USERS.SEARCH_PLACEHOLD")}
              className='text-sm w-full'
              value={userSearchInput}
              onChange={(e) => setUserSearchInput(e.target.value)}
            />
            <FiSearch size={16} className='text-gray-400'/>
          </div>
          {
            isUserSelectOpen &&
            <div
              className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[3rem]'>
              <ul className='flex flex-col gap-2'>
                {
                  allUsers.map((it) =>
                    <li key={it.id} onClick={() => selectUser(it)}
                        className={cx('p-1 px-2 rounded-lg flex items-center gap-3 cursor-pointer hover:bg-purple-100 hover:text-black', {'bg-purple-light text-white': team.includes(it)})}>
                      <div className='border p-1 rounded-full'>
                        <FiUser size={18}/>
                      </div>
                      <p className='font-semibold'>{it.firstName} {it.lastName}</p>
                    </li>
                  )
                }
              </ul>
            </div>
          }
          {team.length > 0 &&
            team.map((it) =>
              <div key={it.id} className='flex flex-col gap-3 mt-4'>
                <div className={styles.projectTeam_usersWrapper}>
                  <div className='flex items-center gap-3'>
                    <div className='border p-1 rounded-full'>
                      <FiUser size={28}/>
                    </div>
                    <div>
                      <p className='text-sm font-semibold'>{it.firstName} {it.lastName}</p>
                      {/*<p className='text-xs font-semibold text-gray-500'>full stack developer</p>*/}
                    </div>
                  </div>
                  <FiTrash onClick={() => removeUser(it)} className='text-red-700 cursor-pointer' size={16}/>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className='mt-6 flex items-center justify-between min-w-[40vw]'>
        <button onClick={() => submitDeleteProject()} className="text-red-700 border border-red-700 text-sm font-semibold px-5 py-2.5 rounded-lg">
          Delete project
        </button>
        {!loading &&
          <button onClick={() => submit()} className={styles.mainBtn}>
            Save project
          </button>
        }
        {loading &&
          <div className="flex items-center justify-center px-4 w-28 h-10 bg-purple-light rounded-lg">
            <div className="loader"/>
          </div>
        }
      </div>
    </div>
  )
}

function EditProjectModal({close, oldData}) {

  const [name, setName] = useState("");
  const [deadline, setDeadLine] = useState(null);
  const [userSearchInput, setUserSearchInput] = useState("");
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([{id: 1, name: "test"}, {id: 2, name: "test"}]);
  const [team, setTeam] = useState([]);

  const selectUser = (user) => {
    if (!team.includes(user)) {
      setTeam(oldArray => [...oldArray, user]);
      setUserSearchInput("");
    }
  }

  const submit = () => {
    console.log(team);
  }

  useEffect(() => {
    if (userSearchInput.length > 0) {
      setIsUserSelectOpen(true);
    } else {
      setIsUserSelectOpen(false);
    }
  }, [userSearchInput]);

  return (
    <div className='min-h-[50vh] flex flex-col justify-between'>
      <div>
        <div className='grid grid-cols-3 gap-2 mb-4'>
          <input
            placeholder='Project name'
            className='text-sm border py-2 px-3 h-10 rounded-lg col-span-2'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <DatePicker
            className='py-[9px] px-3 text-sm w-full border rounded-lg'
            dateFormat="dd/MM/yyyy"
            locale="fr"
            isClearable={true}
            placeholderText="Deadline"
            selected={deadline}
            onChange={(e) => setDeadLine(e)}
          />
        </div>
        <div className='border rounded-lg p-2 pt-4 relative'>
          <div className='absolute top-[-10px] left-4 px-2 bg-white text-xs font-semibold text-gray-500'>
            Project team
          </div>
          <div className='border py-2 px-3 h-10 flex items-center justify-between rounded-lg gap-2'>
            <input
              placeholder='Search user'
              className='text-sm w-full'
              value={userSearchInput}
              onChange={(e) => setUserSearchInput(e.target.value)}
            />
            <FiSearch size={16} className='text-gray-400'/>
          </div>
          {
            isUserSelectOpen &&
            <div
              className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[3rem]'>
              <ul className='flex flex-col gap-2'>
                {
                  allUsers.map((it) =>
                    <li key={it.id} onClick={() => selectUser(it)}
                        className={cx('p-1 px-2 rounded-lg flex items-center gap-3 cursor-pointer hover:bg-purple-100 hover:text-black', {'bg-purple-light text-white': team.includes(it)})}>
                      <div className='border p-1 rounded-full'>
                        <FiUser size={18}/>
                      </div>
                      <p className='font-semibold'>{it.name}</p>
                    </li>
                  )
                }
              </ul>
            </div>
          }
          <div className='flex flex-col gap-3 mt-4'>
            <div className={styles.projectTeam_usersWrapper}>
              <div className='flex items-center gap-3'>
                <div className='border p-1 rounded-full'>
                  <FiUser size={28}/>
                </div>
                <div>
                  <p className='text-sm font-semibold'>User name</p>
                  <p className='text-xs font-semibold text-gray-500'>full stack developer</p>
                </div>
              </div>
              <FiTrash className='text-red-700 cursor-pointer' size={16}/>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-6 flex items-center justify-end'>
        <button onClick={() => submit()} className={styles.mainBtn}>
          Save project
        </button>
      </div>
    </div>
  )
}

function ProjectCard({it, openEditProjectModal, fetchProjects}) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const statusMenuRef = useRef(null);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);

  const [statusList, setStatusList] = useState([]);

  const changeStatus = (e, newStatus) => {
    e.stopPropagation();
    getProjectById(it.id)
      .then((res)=>{
        const projectData = {
          "name": res.name,
          "deadline": res.deadline,
          "members": res.members.map((it) => it.id),
          "status": newStatus
        }
        updateProject(projectData, it.id)
          .then((res) => {
            console.log(res);
            fetchProjects();
            setIsStatusMenuOpen(false);
          })
          .catch((err) => console.log(err))
      })
      .catch((err)=>{ console.log(err) });
  }

  const fetchStatusList = async () => {
    getAllProjectStatus()
      .then((res) => {
        setStatusList(res);
      })
      .catch((err) => console.log(err));
  }

  const calculePercent = (a, b) => {
    return a / b ? parseFloat((a / b) * 100).toFixed(0) : 0;
  }

  useEffect(() => {
    fetchStatusList();
  }, []);

  const handleClickOutside = (event) => {
    if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
      setIsStatusMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      key={it}
      onClick={()=> navigate(`/overview/${it.id}`) }
      className='smallCustomShadow group relative border rounded-lg p-2 min-h-32 flex flex-col justify-between cursor-pointer hover:bg-gray-100'>
      <p className='font-semibold text-lg mb-2'>{it.name}</p>
      <div>
        <div className='mb-1 flex items-center'>
          {/* <span
            className='px-2 py-1 rounded-full font-semibold text-xs'
            style={{color: statusColors["Paused"].text, backgroundColor: statusColors["Paused"].bg}} >
            Paused
          </span> */}
          {/*<div className='relative'>*/}
          {/*  <button onClick={() => setIsStatusSelectOpen(true)} className={cx(styles.status)}>*/}
          {/*    • {status.name}*/}
          {/*  </button>*/}
          {/*  {*/}
          {/*    isStatusSelectOpen &&*/}
          {/*    <div className={styles.floatStatusMenu} ref={statusSelectRef}>*/}
          {/*      <ul>*/}
          {/*        <li onClick={() => setIsStatusSelectOpen(false)} className={styles.currStatus}>{status.name}</li>*/}
          {/*        {*/}
          {/*          statusList.map((it) =>*/}
          {/*            <li key={it.id} onClick={() => clickStatus(it)} className={styles.status}*/}
          {/*                style={{color: it.color}}>*/}
          {/*              • {it.name}*/}
          {/*            </li>*/}
          {/*          )*/}
          {/*        }*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  }*/}
          {/*</div>*/}
          <div className='relative w-max'>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsStatusMenuOpen(true)
              }}
              className='px-2 py-1 rounded-full font-semibold text-xs border'
              style={{color: EnumsColors[it.status].text, backgroundColor: EnumsColors[it.status].bg}}
            >
              {t(`COMMON.PROJECT_STATUS.${it.status}`)}
            </button>
            {
              isStatusMenuOpen &&
              <div className={styles.floatStatusMenu} ref={statusMenuRef}>
                <ul>
                  <li className={styles.currStatus}>{t(`COMMON.PROJECT_STATUS.${it.status}`)}</li>
                  {
                    ProjectStatus.map((it) =>
                      <li key={it}
                          onClick={(e) => changeStatus(e, it)}
                          className={styles.status}
                          style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}
                      >
                        • {t(`COMMON.PROJECT_STATUS.${it}`)}
                      </li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
          <span className='mx-2 font-bold text-gray-500'>•</span>
          <span className='text-xs font-semibold text-gray-500'>
            {it?.tasksStats?.sumTasks} {t("COMMON.TASKS")}
          </span>
          <span className='mx-2 font-bold text-gray-500'>•</span>
          <span className='text-xs font-semibold text-gray-500'>
            {t("ALL_PROJECTS.DEADLINE")}: {moment(it.deadline).calendar()}
          </span>
        </div>
        <div className='mt-5 flex items-center justify-between gap-2'>
          <div className='flex flex-col items-center min-h-10 gap-1'>
            <div
              className='border-2 border-green-700 text-xs font-bold text-green-700 h-10 w-10 flex items-center justify-center rounded-full'>
              {calculePercent(it?.tasksStats?.finished, it?.tasksStats?.sumTasks)}%
            </div>
            <p className='rounded-full font-semibold text-center'>
              <span className='text-green-700'>{it?.tasksStats?.finished}</span><span
              className='text-xs text-gray-500'>/{it?.tasksStats?.sumTasks} {t("COMMON.TASK_STATUS.FINISHED")}</span>
            </p>
          </div>
          <div className='flex flex-col items-center min-h-10 gap-1'>
            <div
              className='border-2 border-purple-dark text-xs font-bold text-purple-dark h-10 w-10 flex items-center justify-center rounded-full'>
            {calculePercent(it?.tasksStats?.inprogress, it?.tasksStats?.sumTasks)}%
            </div>
            <p className='rounded-full font-semibold text-center'>
              <span className='text-purple-dark'>{it?.tasksStats?.inprogress}</span><span
              className='text-xs text-gray-500'>/{it?.tasksStats?.sumTasks} {t("COMMON.TASK_STATUS.IN_PROGRESS")}</span>
            </p>
          </div>
          <div className='flex flex-col items-center min-h-10 gap-1'>
            <div
              className='border-2 border-red-700 text-xs font-bold text-red-700 h-10 w-10 flex items-center justify-center rounded-full'>
              {calculePercent(it?.tasksStats?.unstarted, it?.tasksStats?.sumTasks)}%
            </div>
            <p className='rounded-full font-semibold text-center'>
              <span className='text-red-700'>{it?.tasksStats?.unstarted}</span><span
              className='text-xs text-gray-500'>/{it?.tasksStats?.sumTasks} {t("COMMON.TASK_STATUS.DELAYED")}</span>
            </p>
          </div>
          <div className='flex flex-col items-center min-h-10 gap-1'>
            <div
              className='border-2 border-gray-400 text-xs font-bold text-gray-400 h-10 w-10 flex items-center justify-center rounded-full'>
              {calculePercent(it?.tasksStats?.paused, it?.tasksStats?.sumTasks)}%
            </div>
            <p className='rounded-full font-semibold text-center'>
              <span className='text-gray-400'>{it?.tasksStats?.paused}</span><span
              className='text-xs text-gray-500'>/{it?.tasksStats?.sumTasks} {t("COMMON.TASK_STATUS.PAUSED")}</span>
            </p>
          </div>
        </div>
        {/* <ProgressBar progress={66} /> */}
      </div>
      <div onClick={(e) => {
        e.stopPropagation();
        openEditProjectModal(it)
      }} className='bg-white p-1.5 rounded-lg absolute top-1 right-1 border hidden group-hover:block'>
        <FiEdit/>
      </div>
    </div>
  )
}

export default function AllProjects() {

  const statusFilterRef = useRef(null);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [isEditProjectModalOpen, setIsEditProjectModalOpen] = useState(false);
  const [editProjectData, setEditProjectData] = useState({});
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [projects, setProjects] = useState({});
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchInput, setSearchInput] = useState("");

  const [isLastPage, setIsLastPage] = useState(false);
  const [page, setPage] = useState(1);
  const [sizeOfPage, setSizeOfPage] = useState(10);

  const toggleCheckStatusFilter = (item) => {
    if (statusFilter.some(it => it === item)) {
      setStatusFilter(statusFilter.filter(it => it !== item));
    } else {
      setStatusFilter(oldArray => [...oldArray, item]);
    }
  }

  const openNewProjectModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewProjectModalOpen(true);
  }
  const closeNewProjectModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewProjectModalOpen(false);
    fetchProjects();
  }

  const openEditProjectModal = (data) => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsEditProjectModalOpen(true);
    setEditProjectData(data);
  }
  const closeEditProjectModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditProjectModalOpen(false);
    setEditProjectData({});
    fetchProjects();
  }

  const fetchProjects = () => {
    getProjectsPage(page, sizeOfPage, searchInput)
      .then((res) => {
        setProjects(res.content);
        setIsLastPage(res.last);
      })
      .catch((err) => {
        console.log(err)
      });
  }

  useEffect(() => {
    fetchProjects();
  }, [searchInput, page, sizeOfPage]);

  const handleClickOutside = (event) => {
    if (statusFilterRef.current && !statusFilterRef.current.contains(event.target)) {
      setIsStatusFilterOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="mt-6 flex items-center justify-between">
        <h2 className='text-xl font-semibold'>{t("ALL_PROJECTS.TITLE")}</h2>
        <div className='flex items-center gap-2'>
          <button onClick={() => openNewProjectModal()} className={styles.mainBtn}>
            {t("ALL_PROJECTS.ADD_BTN")}
          </button>
          {/*<button onClick={() => navigate(`/overview/all`)}*/}
          {/*        className="border rounded-lg font-semibold h-full py-2 px-5 hover:border-purple-light hover:text-purple-light">*/}
          {/*  {t("COMMON.SEE_ALL")}*/}
          {/*</button>*/}
        </div>
      </div>
      <div className='flex items-center gap-4 mt-4'>
        <div className='w-1/4'>
          <DatePicker
            wrapperClassName='w-full'
            className='border rounded-lg py-[10px] px-2 text-sm w-full'
            placeholderText={t("ALL_PROJECTS.DATE_FILTER")}
            locale="fr"
            dateFormat="dd/MM/yyyy"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
          />
        </div>
        <div ref={statusFilterRef} className='relative w-1/4'>
          <button onClick={() => setIsStatusFilterOpen(!isStatusFilterOpen)}
                  className='truncate overflow-hidden relative text-left border rounded-lg text-sm pl-2 pr-8 min-w-44 w-full py-[10px]'>
            {statusFilter.length > 0
              ? statusFilter.map(it =>
                <span className='px-2 py-1 rounded-full font-semibold text-xs mr-2'
                      style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}>
                  {t(`COMMON.PROJECT_STATUS.${it}`)}
                </span>
              )
              : <span className='text-gray-400'>{t("COMMON.STATUS")}</span>
            }
            <span className='absolute right-3 top-[50%] translate-y-[-50%]'>
              {isStatusFilterOpen
                ? <FiChevronUp className='text-gray-500' size={16}/>
                : <FiChevronDown className='text-gray-500' size={16}/>
              }
            </span>
          </button>
          {isStatusFilterOpen &&
            <ul className='customShadow bg-white rounded-lg absolute top-10 left-0 w-full z-20'>
              {
                ProjectStatus.map((it) =>
                  <li key={it} className='p-2 hover:bg-gray-100 rounded-lg'>
                    <Row>
                      <CheckBox isChecked={statusFilter.some(item => item === it)}
                                toggleCheck={() => toggleCheckStatusFilter(it)}/>
                      <div style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}
                           className='px-2 py-1 rounded-full font-semibold text-xs ml-2'
                      >
                        {t(`COMMON.PROJECT_STATUS.${it}`)}
                      </div>
                    </Row>
                  </li>
                )
              }
            </ul>
          }
        </div>
        <div className='border py-[10px] px-2 rounded-lg flex items-center justify-between w-1/2'>
          <input
            placeholder={t("ALL_PROJECTS.SEARCH_PLACEHOLD")}
            className='text-sm'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <FiSearch size={16} className='text-gray-400'/>
        </div>
      </div>
      <div className="h-full min-h-[75vh] flex flex-col justify-between" >
        <div className='grid grid-cols-3 mt-4 gap-4'>
          {projects.length > 0 &&
            projects.map((it) =>
              <ProjectCard key={it.id} it={it} openEditProjectModal={openEditProjectModal}
                           fetchProjects={fetchProjects}/>
            )
          }
        </div>
        <div className='mt-4 flex items-center justify-between'>
          <div className='flex gap-3 items-center'>
            <button onClick={() => page > 1 && setPage(page - 1)}
                    className='border rounded-full p-1 hover:text-purple-light hover:border-purple-light'>
              <FiChevronLeft size={16}/>
            </button>
            <span className='font-semibold text-sm'>{page}</span>
            <button onClick={() => !isLastPage && setPage(page + 1)}
                    className='border rounded-full p-1 hover:text-purple-light hover:border-purple-light'>
              <FiChevronRight size={16}/>
            </button>
          </div>
          <div className='flex items-center w-max gap-5'>
            <label className='w-max text-sm'>{t("COMMON.PAGINATION_TITLE")}</label>
            <select id="sizeOfPage" defaultValue={10} onChange={(e) => setSizeOfPage(e.target.value)}
                    className="appearance-none bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-14 p-2">
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
        </div>
      </div>
      <Modal isOpen={isNewProjectModalOpen} close={closeNewProjectModal} title="Ajouter un projet">
        <NewProjectModal close={closeNewProjectModal}/>
      </Modal>
      <Modal isOpen={isEditProjectModalOpen} close={closeEditProjectModal} title="Modifier le projet">
        <EditProjectModalV2 close={closeEditProjectModal} oldData={editProjectData}/>
      </Modal>
    </div>
  )
}
