import React, {useContext, useRef, useState, useEffect} from 'react';
import styles from '../Styles/myTasks.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';
import { FiClock, FiEdit2, FiFolder, FiUser } from 'react-icons/fi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import SideModal from './SideModal';
import { TaskModal } from './Overview';
import { AnimatePresence } from 'framer-motion';
import {getAllFinishedTasks, getAllTasks, updateTaskStatus} from "../services/taskService";
import {EnumsColors, TaskStatus} from "../Ressources/enums";

const myTasks = [
  { id: 1, task: 'Rédiger une proposition de projet', assignedTo: 'Adil Stifi', dueDate: '04-01-2024', status: "Pas commencé", priority: "Low", subTasks: [{id: 1, subTask: "test 1", status: "Pas commencé"} ,{id: 2, subTask: "test 2", status: "En cours"}] },
  { id: 2, task: 'Planifier la réunion de lancement', assignedTo: 'Adil Stifi', dueDate: '03-01-2024', status: "En cours", priority: "Medium", subTasks: [] },
  { id: 3, task: 'Consulter les résultats de la recherche', assignedTo: 'Adil Stifi', dueDate: '17-04-2024', status: "Terminé", priority: "Hight", subTasks: [] }
];

const categorizeTasks = (tasks) => {
  const categories = {
    today: [],
    tomorrow: [],
    thisWeek: [],
    later: []
  };

  tasks.forEach(task => {
    const dueDate = moment(task.shouldStartAt, "DD-MM-YYYY");
    const today = moment();
    const tomorrow = moment().add(1, 'days');

    if (dueDate.isSame(today, 'day')) {
      categories.today.push(task);
    } else if (dueDate.isSame(tomorrow, 'day')) {
      categories.tomorrow.push(task);
    } else if (dueDate.isSameOrAfter(today.startOf('week')) && dueDate.isSameOrBefore(today.endOf('week').subtract(2, 'days'))) {
      categories.thisWeek.push(task);
    } else {
      categories.later.push(task);
    }
  });

  return categories;
};

function TaskContainer({provided, task, openTaskModal}){

  const {t} = useTranslation();
  const statusMenuRef = useRef(null);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);

  const changeStatus = (e, newStatus)=>{
    e.stopPropagation();
    task.status = newStatus;
    updateTaskStatus({"status": newStatus} ,task.id)
      .then((res)=> {
        // fetchTasks();
      })
      .catch((err)=> console.log(err) );
    setIsStatusMenuOpen(false);
  }

  const handleClickOutside = (event) => {
    if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
      setIsStatusMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <div onClick={()=> openTaskModal(task) } ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.task}>
      <div className='flex items-center justify-between gap-1.5 text-xs text-gray-500 mb-3' >
        <div className='flex items-center gap-1.5 text-xs' >
          <FiFolder size={12}  />
          <p>Project name</p>
        </div>
        <div className='flex items-center gap-1.5 text-xs truncate max-w-[40%]' >
          <FiClock size={12} className='w-5' />
          <span className='truncate' >{moment(task?.shouldStartAt).add(task.estimation, 'day').format('DD-MM-YYYY')}</span>
        </div>
      </div>
      <p className='font-semibold mb-4' >{task.name}</p>
      <div className='flex items-center gap-2 text-gray-500' >
        <div
          style={{color: EnumsColors[task.priority].text, backgroundColor: EnumsColors[task.priority].bg}}
          className={styles.priority}
        >
          {t(`COMMON.TASK_PRIORITY.${task.priority}`)}
        </div>
        <span>•</span>
        <div className='relative w-max' >
          <button
            onClick={(e)=>{ e.stopPropagation(); setIsStatusMenuOpen(true)}}
            className={cx(styles.status, {[styles.progress]: task.status === "IN_PROGRESS", [styles.finish]: task.status === "FINISHED", [styles.pause]: task.status === "PAUSED"})}
          >
            {t(`COMMON.TASK_STATUS.${task.status}`)}
          </button>
          {
            isStatusMenuOpen &&
            <div className={styles.floatStatusMenu} ref={statusMenuRef} >
              <ul>
                <li className={styles.currStatus} >{t(`COMMON.TASK_STATUS.${task.status}`)}</li>
                {
                  TaskStatus.map((it) =>
                    <li
                      key={it}
                      onClick={(e) => changeStatus(e, it)}
                      className={cx(styles.status,{[styles.progress]: it === "IN_PROGRESS", [styles.finish]: it === "FINISHED", [styles.pause]: it === "PAUSED"})}>
                      • {t(`COMMON.TASK_STATUS.${it}`)}
                    </li>
                  )
                }
              </ul>
            </div>
          }
        </div>
      </div>
      <div className={styles.taskEditIcon} >
        <FiEdit2/>
      </div>
    </div>
  )
}

function KanbanColumn({ title, tasks, bgColor, titleColor, droppableId, openTaskModal }){

  return(
    <div className={cx(styles.kanbanColumn, styles.openned_kanbanColumn)} style={{backgroundColor: bgColor}}>
      <div className={styles.header} style={{color: titleColor}}> • {title}</div>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={styles.content}>
            {tasks.map((task, index) => (
              <Draggable key={task.id} draggableId={task.id.toString()} index={index} isDragDisabled={true} >
                {(provided) => (
                  <TaskContainer provided={provided} task={task} openTaskModal={openTaskModal} />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

function KanbanView({openTaskModal, projectId}) {

  const { t } = useTranslation();

  const mainStatus = {
    today: moment().format("DD-MM-YYYY"),
    tomorrow: moment().add(1, 'days').format("DD-MM-YYYY"),
    thisWeek: moment().endOf('isoWeek').subtract(2, 'days').format("DD-MM-YYYY"),
    later: moment().add(1, 'week').startOf('isoWeek').format("DD-MM-YYYY")
  };
  const mainTitles = {
    today: t("PROJECT.TIME_KANBAN.TODAY"),
    tomorrow: t("PROJECT.TIME_KANBAN.TOMORROW"),
    thisWeek: t("PROJECT.TIME_KANBAN.THIS_WEEK"),
    later: t("PROJECT.TIME_KANBAN.LATER")
  }

  const [tasks, setTasks] = useState({});

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        tasks[source.droppableId],
        source.index,
        destination.index
      );

      const newState = {
        ...tasks,
        [source.droppableId]: items,
      };

      setTasks(newState);
    } else {
      const result = move(
        tasks[source.droppableId],
        tasks[destination.droppableId],
        source,
        destination
      );

      setTasks({
        ...tasks,
        ...result,
      });
    }
  };

  // Reorder the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Moves an item from one list to another list
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    console.log(droppableSource, droppableDestination);
    destClone.splice(droppableDestination.index, 0, removed);
    destClone[droppableDestination.index].dueDate = mainStatus[droppableDestination.droppableId]
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const fetchTasks = async ()=>{
    const tasksData= {
      "keyWord": "",
      "projectId": projectId
    }
    const params = {
      page: 1,
      size: 10,
      sort: `id,desc`,
    };

    let allTasks = [];
    await getAllTasks(tasksData, params)
      .then((res)=> {
        allTasks.push(...res.content);
      })
      .catch((err)=> console.log(err) );

    await getAllFinishedTasks(tasksData, params)
      .then((res)=>{
        allTasks.push(...res.content);
      })
      .catch((err)=> console.log(err) );

    setTasks(categorizeTasks(allTasks));
  }

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <div className={styles.kanbanBoard}>
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.entries(tasks).map(([category, tasksInCategory]) => (
          <KanbanColumn
            key={category}
            title={mainTitles[category]}
            tasks={tasksInCategory}
            bgColor="#F5FAFC"
            titleColor="#5A97BD"
            droppableId={category}
            openTaskModal={openTaskModal}
          />
        ))}
      </DragDropContext>
    </div>
  );
}

export default function TasksTimeView({projectId}) {

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [taskData, setTaskData] = useState({});

  const openTaskModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsTaskModalOpen(true);
    setTaskData(task);
  }
  const closeTaskModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTaskModalOpen(false);
    setTaskData({});
  }

  return (
    <div >

      <div className='mt-6' >
        <KanbanView openTaskModal={openTaskModal} projectId={projectId} />
      </div>
      <AnimatePresence>
        {
          isTaskModalOpen &&
          <SideModal isOpen={isTaskModalOpen} title="side modal" close={closeTaskModal} >
            <TaskModal close={closeTaskModal} task={taskData} />
          </SideModal>
        }
      </AnimatePresence>
    </div>
  )
}
