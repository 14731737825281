import api from "./api";

export const getMyInfo = async ()=>{
  try {
    const response = await api.get('/admin/account/');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateMyAccount = async (formData) =>{

  try {
    const response = await api.put('/admin/account/update', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const checkToken = async ()=>{
  try {
    const response = await api.get('/admin/account/check');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
