import api from "./api";

export const getAllTeams = async ()=>{
  try {
    const response = await api.get(`/regular/units/all`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const createTeam = async (data)=>{
  try {
    const response = await api.post('/regular/units/create', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateTeam = async (data, unitId)=>{
  try {
    const response = await api.put(`/regular/units/update/${unitId}`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteTeam = async (unitId)=>{
  try {
    const response = await api.delete(`/regular/units/delete/${unitId}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

