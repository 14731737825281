import axios from "axios";

export const login = async (data)=>{
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/auth/basic`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
