import api from "./api";

export const setMainStatistique = async ()=>{
  try {
    const response = await api.get('/admin/statistiques/');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getBarChartStatistique = async ()=>{
  try {
    const response = await api.get('/admin/statistiques/users-by-client');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
