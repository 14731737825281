import api from "./api";

export const getAllSprint = async (data)=>{
  try {
    const response = await api.post('/regular/sprints/by-project', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllSprintStatus = async ()=>{
  try {
    const response = await api.get('/regular/referentiels/sprint-status/all');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const createSprint = async (data)=>{
  try {
    const response = await api.post('/regular/sprints/create', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateSprint = async (data, id)=>{
  try {
    const response = await api.put(`/regular/sprints/update/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteSprint = async (id)=>{
  try {
    const response = await api.delete(`/regular/sprints/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const reOrderSprints = async (data)=>{
  try {
    const response = await api.put(`/regular/sprints/reorder`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
