import styles from '../Styles/account.module.scss';
import React, {useContext, useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle, FiEdit2, FiGrid, FiUser, FiX, FiXOctagon } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import cx from 'classnames';

import { MyPerformanceTaskContainer } from './MyPerformance';
import SideModal from './SideModal';
import { TaskModal } from './Overview';
import Modal from './Modal';
import {UserContext} from "../Contexts/UserContext";

const tasksTest = [
  { id: 1, task: 'Rédiger une proposition de projet', assignedTo: 'Adil Stifi', dueDate: '08/01/2024', status: "Pas commencé", priority: "Hight", subTasks: [] },
  { id: 2, task: 'Planifier la réunion de lancement', assignedTo: 'Adil Stifi', dueDate: '03/01/2024', status: "En cours", priority: "Medium", subTasks: [] },
  { id: 3, task: 'Rédiger une proposition de projet', assignedTo: 'Adil Stifi', dueDate: '08/01/2024', status: "Pas commencé", priority: "Hight", subTasks: [] },
  { id: 4, task: 'Planifier la réunion de lancement', assignedTo: 'Adil Stifi', dueDate: '03/01/2024', status: "En cours", priority: "Medium", subTasks: [] },
];

function EditModal(){

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t } = useTranslation();
  const [isEmailValide, setIsEmailValide] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userCreated, setUserCreated] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);

  const onSubmit = (data)=>{
    setLoading(true);
    const formData = new FormData();

    const userData = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: true,
      password: isEditPassword ? data.password : null,
      role: data.role
    }

    formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));

    setUserCreated(true)
    setLoading(false);

  }


  if(userCreated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("USERS.MODAL.USER_CREATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw] min-w-[35vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
        <div className='flex items-center justify-between gap-3 mb-3' >
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.LAST_NAME")} *</label>
              { errors.lastName && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("USERS.MODAL.LAST_NAME")}
              {...register("lastName", { required: true })}
            />
          </div>
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.FIRST_NAME")} *</label>
              { errors.firstName && <label className='text-xs text-red-700 font-semibold truncate max-w-[40%]' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("USERS.MODAL.FIRST_NAME")}
              {...register("firstName", { required: true })}
            />
          </div>
        </div>
        <div className='flex flex-col w-full mb-3' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.ROLE")} *</label>
            { errors.role && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("USERS.MODAL.ROLE")}
            {...register("role", { required: true })}
          />
        </div>
        <div className='border p-2 rounded-lg' >
          <div className='flex items-center justify-between' >
            <p className='text-gray-500 text-sm font-semibold' >{t("USERS.MODAL.PASSWORD")}</p>
            <div className='border rounded-full p-1 cursor-pointer' onClick={()=> setIsEditPassword(!isEditPassword) } >
              <FiX className={cx('transition duration-300', {'rotate-0': isEditPassword, 'rotate-45': !isEditPassword})} />
            </div>
          </div>
          <motion.div
            initial="closed"
            animate={isEditPassword ? "open" : "closed"}
            variants={{
              open: { opacity: 1, height: 'auto' },
              closed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.5 }}
            className={cx('flex flex-col', {'mt-3': isEditPassword})}
          >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label onClick={()=> console.log(errors.password) } className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.PASSWORD")}</label>
              { errors.password && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("USERS.MODAL.PASSWORD")}
              // {...register("password", { required: false })}
              {...register("password", {
                required: isEditPassword ? "required" : false,
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit contenir au moins 8 caractères"
                },
                maxLength: {
                  value: 16,
                  message: "Le mot de passe ne doit pas dépasser 16 caractères"
                },
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                  message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
                }
              })}
            />
            <div className='px-3.5' >
              <p className='text-xs text-gray-500' >{errors.password ? errors.password.message !== "required" && errors.password.message : null }</p>
            </div>
          </motion.div>
        </div>
        <div className='flex justify-end mt-6' >
          {
            loading ?
            <div className='border border-azure rounded-lg h-9 w-32 relative' >
              <div className='loader'/>
            </div>
            :
            <input
              type="submit"
              value={t("COMMON.SAVE")}
              className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-purple-light'
            />
          }
        </div>
      </form>
    </div>
  )
}

export default function Account() {

  const {user} = useContext(UserContext);
  const {t} = useTranslation();

  const profileRef = useRef(null);
  const savedRef = useRef(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [currTab, setCurrTab] = useState("profile");
  const [taskData, setTaskData] = useState({});
  const [dataTest] = useState([
    {id: 1, title: "Total des projets", number: "05", color: "#2a2a66"},
    {id: 2, title: "Total des tâches", number: "30", color: "#"},
    {id: 3, title: "Tâches terminées", number: "05", color: "#23B301"},
    {id: 4, title: "Tâches en cours", number: "05", color: "#7878c7"},
    {id: 5, title: "Tâches retardées", number: "05", color: "#D6323A"},
  ]);

  const openEditModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsEditModalOpen(true);
  }
  const closeEditModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditModalOpen(false);
  }

  const openTaskModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsTaskModalOpen(true);
    setTaskData(task);
  }
  const closeTaskModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTaskModalOpen(false);
    setTaskData({});
  }
  useEffect(() => {
    if (currTab === "profile" && profileRef.current) {
      profileRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (currTab === "saved" && savedRef.current) {
      savedRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currTab]);

  return (
    <div className='mt-6' >
      <div className='rounded-lg flex gap-2 items-center justify-between mb-4' >
        <div className='flex items-center gap-4' >
          <div className='p-2 border rounded-full' >
            <FiUser size={38} />
          </div>
          <div >
            <h2 className='font-bold text-xl mb-1' >{user.firstName} {user.lastName}</h2>
            <h4 className='font-semibold text-sm text-gray-500' >{user.username}</h4>
          </div>
        </div>
        <div className='grid grid-cols-5 gap-3 w-[70%]' >
          {
            dataTest.map((it)=>
              <div key={it.id} className='flex flex-col justify-between' >
                <p className='text-sm text-gray-400 font-semibold mb-1' >{it.title}</p>
                <div className='text-2xl font-semibold border-l-2 pl-2' style={{borderColor: it.color}} >
                  {it.number}
                </div>
              </div>
            )
          }
        </div>
      </div>

      <div className="border-b border-gray-200 dark:border-gray-700 mb-4">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-400">
          <li className="me-2">
            <button onClick={()=> setCurrTab("profile") } className={cx("inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group", {'border-b-purple-light text-purple-light hover:border-b-purple-dark hover:text-purple-dark': currTab === "profile" })}>
              <div className={cx("w-4 h-4 me-2 group-hover:text-gray-500 dark:text-gray-500", {'group-hover:text-purple-dark': currTab === "profile"})} >
                <FiUser size={17} />
              </div>
              {t("ACCOUNT.INFORMATIONS_TITLE")}
            </button>
          </li>
          <li className="me-2">
            <button onClick={()=> setCurrTab("saved") } className={cx("inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group", {'border-b-purple-light text-purple-light hover:border-b-purple-dark hover:text-purple-dark': currTab === "saved" })}>
              <div className={cx("w-4 h-4 me-2 group-hover:text-gray-500 dark:text-gray-500", {'group-hover:text-purple-dark': currTab === "saved"})} >
                <FiGrid size={17} />
              </div>
              {t("ACCOUNT.SAVED_TASKS")}
            </button>
          </li>
        </ul>
      </div>
      <div className='w-full flex overflow-x-hidden flex-nowrap' >
        <div ref={profileRef} className={styles.tabContainer} >
          <div className='border smallCustomShadow px-3 pb-2 pt-1 rounded-lg flex flex-col gap-2 relative' >
            <button onClick={()=> openEditModal() } className='p-2 hover:bg-gray-100 rounded-full absolute top-2 right-3' >
              <FiEdit2 size={16} />
            </button>
            <div>
              <label className='text-xs text-gray-400 mb-0.5' >{t("USERS.MODAL.FIRST_NAME")}</label>
              <p className='font-semibold' >{user.firstName}</p>
            </div>
            <div>
              <label className='text-xs text-gray-400 mb-0.5' >{t("USERS.MODAL.LAST_NAME")}</label>
              <p className='font-semibold' >{user.lastName}</p>
            </div>
            <div>
              <label className='text-xs text-gray-400 mb-0.5' >{t("USERS.MODAL.EMAIL")}</label>
              <p className='font-semibold' >{user.email}</p>
            </div>
            <div>
              <label className='text-xs text-gray-400 mb-0.5' >{t("USERS.MODAL.ROLE")}</label>
              <p className='font-semibold' >.</p>
            </div>
          </div>
        </div>
        <div ref={savedRef} className={styles.tabContainer} >
          <div className='grid grid-cols-3 gap-x-2' >
            {tasksTest.map((task) => (
              <MyPerformanceTaskContainer key={task.id} task={task} openTaskModal={openTaskModal} />
            ))}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {
          isTaskModalOpen &&
          <SideModal isOpen={isTaskModalOpen} title="side modal" close={closeTaskModal} >
            <TaskModal close={closeTaskModal} task={taskData} />
          </SideModal>
        }
      </AnimatePresence>
      <Modal isOpen={isEditModalOpen} close={closeEditModal} title={t("ACCOUNT.INFORMATIONS_TITLE")} >
        <EditModal close={closeEditModal} />
      </Modal>
    </div>
  )
}
