import api from "./api";

export const getMineProjects = async ()=>{
  try {
    const response = await api.get('/regular/projects/mine');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getProjectById = async (projectId)=>{
  try {
    const response = await api.get(`/regular/projects/show/${projectId}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getProjectsPage = async (page, size, searchText)=>{

  const params = {
    page: page,
    size: size,
    sort: `updatedAt,desc`,
  };

  try {
    const response = await api.post('/regular/projects/page',{keyWord: searchText}, { params });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllProjectStatus = async ()=>{
  try {
    const response = await api.get('/regular/referentiels/project-status/all');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const createProject = async (data)=>{
  try {
    const response = await api.post('/regular/projects/create', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateProject = async (data, projectId)=>{
  try {
    const response = await api.put(`/regular/projects/update/${projectId}`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteProject = async (projectId)=>{
  try {
    const response = await api.delete(`/regular/projects/delete/${projectId}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
