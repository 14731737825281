import React, {useState} from 'react';
import styles from '../Styles/auth.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import axios from 'axios';

import {loginEvent} from '../Contexts/events';
import { Button, Input } from '../Styles/DefaultComponents';
import {login} from "../services/authService";
import bromeLogo from '../Assets/brome-logo.png';

export default function Login() {

  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPassHidden, setIsPassHidden] = useState(true);

  const submitLogin = ()=>{
    if(email.length === 0 || password.length === 0 ){
      setError("ALL_REQUIRED");
      return null
    }
    setLoading(true);
    const authData = {
      username: email,
      password: password
    }
    login(authData)
      .then((res)=>{
        localStorage.setItem('accessToken', res.accessToken);
        if (res.refreshToken) {
          localStorage.setItem('refreshToken', res.refreshToken);
        }
        if (email.split('_')[0] === "master"){
          loginEvent.emit("master", true);
        }else{
          loginEvent.emit("master", false);
        }
        loginEvent.emit("login", true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.message);
      })
      .finally(()=> setLoading(false) );
  }

  return (
    <div className={styles.container} >
      <div className={styles.content} >
        <div className="absolute top-5 px-5 flex items-center w-[45vw] justify-between" >
          <div className='flex items-center gap-2'>
            <div className='bg-[#2a2a66] rounded-full w-14 h-14 flex items-center justify-center'>
              <svg width="36" height="36" fill="currentColor" className='text-white ml-0.5
              ' viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
                <path
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                <path
                  d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
              </svg>
            </div>
            <span style={{color: '#2a2a66', fontWeight: 600}}>Tasks manager</span>
          </div>
          <div className="w-36 h-14" >
            <img src={bromeLogo} className="w-full h-full object-contain" alt="brome-logo" />
          </div>
        </div>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{t('login.title')}</h1>
          {error && <p className={styles.error}>{t(`ERRORS.${error}`)}</p>}
        </div>
        <div className="flex flex-col gap-5 w-full">
          <Input
            label={t('login.email.label')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder={t('login.email.input')}
            onFocus={() => setError(null)}
            style={{width: '100%'}}
          />
          <Input
            label={t('login.password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder={t('login.password')}
            onFocus={() => setError(null)}
            style={{width: '100%'}}
            isPassHidden={isPassHidden}
            setIsPassHidden={setIsPassHidden}
          />
          {!loading && <Button style={{marginTop: '32px'}} type="primary-btn" onClick={submitLogin}>{t('login.btn')}</Button>}
          { loading &&
            <div style={{marginTop: '32px'}} className="h-10 w-full bg-purple-dark rounded-lg flex items-center justify-center">
              <div className="loader"/>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
