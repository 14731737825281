import React,{ createContext, useState, useEffect } from "react";
import {getMyInfo} from "../services/accountService";

export const UserContext = createContext();

export const UserProvider = ({ children, isLogged }) => {

  const [wideSideBarValue, setWideSideBarValue] = useState(false);
  const [theme, setTheme] = useState('light');
  const [project, setProject] = useState("Tasks management app");
  const [user, setUser] = useState({});
  const [userPrivileges, setUserPriviliges] = useState([]);

  const [isTaskChatOpen, setIsTaskChatOpen] = useState(false);
  const [taskChatData, setTaskChatData] = useState({});

  const getUserData = ()=>{
    if(isLogged){
      getMyInfo()
        .then((res)=>{
          setUser(res);
          setUserPriviliges(res.roles.flatMap((it)=> it.privileges.map((it)=> it.code)))
        })
        .catch((err)=> console.log(err) )
    }
  }

  const openTaskChat = (task)=>{
    setIsTaskChatOpen(true);
    setTaskChatData(task);
  }
  const closeTaskChat = ()=>{
    setIsTaskChatOpen(false);
    setTaskChatData({});
  }

  useEffect(()=>{
    getUserData();
  },[isLogged]);

  const values = {
    project,
    setProject,
    wideSideBarValue,
    setWideSideBarValue,
    theme,
    isTaskChatOpen,
    taskChatData,
    openTaskChat,
    closeTaskChat,
    user,
    getUserData,
    userPrivileges
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};
