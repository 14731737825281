import api from "./api";

export const createTasks = async (data)=>{
  try {
    const response = await api.post('/regular/tasks/create', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateTasks = async (data, taskId)=>{
  try {
    const response = await api.put(`/regular/tasks/update/${taskId}`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getPriorities = async ()=>{
  try {
    const response = await api.get('/regular/referentiels/priorities/all');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllTasks = async (data, params)=>{
  try {
    const response = await api.post('/regular/tasks/page', data, { params });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllFinishedTasks = async (data, params)=>{
  try {
    const response = await api.post('/regular/tasks/page-finished', data, { params });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteTask = async (taskId)=>{
  try {
    const response = await api.delete(`/regular/tasks/delete/${taskId}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getTaskById = async (taskId)=>{
  try {
    const response = await api.get(`/regular/tasks/show/${taskId}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateTaskStatus = async (data, taskId)=>{
  try {
    const response = await api.put(`/regular/tasks/update-status/${taskId}`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateTaskSprint = async (data, taskId)=>{
  try {
    const response = await api.put(`/regular/tasks/update-sprint/${taskId}`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateTaskPriority = async (data, taskId)=>{
  try {
    const response = await api.put(`/regular/tasks/update-priority/${taskId}`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

