import api from "./api";

export const getAllTenants = async ()=>{
  try {
    const response = await api.get('/admin/tenants/all');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const showTenant = async (id)=>{
  try {
    const response = await api.get(`/admin/tenants/show/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllTenantsPage = async (page, size, search) =>{

  const params = {
    page: page,
    size: size,
    sort: `id,desc`,
  };

  const formData = new FormData();
  formData.append('filter', new Blob([JSON.stringify({
    keyWord: search,
  })], { type: 'application/json' }));

  try {
    const response = await api.post('/admin/tenants/page', formData, { params });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const createTenant = async (formData) =>{
  try {
    const response = await api.post('/admin/tenants/create', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    // console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateTenant = async (formData) =>{
  try {
    const response = await api.put('/admin/tenants/update', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteTenant = async (id)=>{
  try {
    const response = await api.delete(`/admin/tenants/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
