import React, {useContext, useRef, useState, useEffect} from 'react';
import styles from '../Styles/myTasks.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';
import { FiClock, FiEdit2, FiFolder, FiUser } from 'react-icons/fi';
import moment from 'moment';

import { CheckBox, Row } from '../Styles/DefaultComponents';
import SideModal from './SideModal';
import { TaskModal } from './Overview';
import { AnimatePresence } from 'framer-motion';

const status = [
  {id: "1", title: "Pas commencé"},
  {id: "2", title: "En cours"},
  {id: "3", title: "Terminé"},
];

const priorities = [
  {id: "1", title: "Low"},
  {id: "2", title: "Medium"},
  {id: "3", title: "Hight"},
];

const projectsTest = [
  {id: "1", title: "Project 1"},
  {id: "2", title: "Project 2"},
  {id: "3", title: "Project 3"},
  {id: "4", title: "Project 4"},
];

const myTasks = [
  { id: 1, task: 'Rédiger une proposition de projet', assignedTo: 'Adil Stifi', dueDate: '04-01-2024', status: "Pas commencé", priority: "Low" },
  { id: 2, task: 'Planifier la réunion de lancement', assignedTo: 'Adil Stifi', dueDate: '03-01-2024', status: "En cours", priority: "Medium" },
  { id: 3, task: 'Consulter les résultats de la recherche', assignedTo: 'Adil Stifi', dueDate: '07-01-2024', status: "Terminé", priority: "Hight" }
];


const categorizeTasks = (tasks) => {
  const categories = {
    today: [],
    tomorrow: [],
    thisWeek: [],
    later: []
  };

  tasks.forEach(task => {
    const dueDate = moment(task.dueDate, "DD-MM-YYYY");
    const today = moment();
    const tomorrow = moment().add(1, 'days');

    if (dueDate.isSame(today, 'day')) {
      categories.today.push(task);
    } else if (dueDate.isSame(tomorrow, 'day')) {
      categories.tomorrow.push(task);
    } else if (dueDate.isBefore(today.endOf('week'))) {
      categories.thisWeek.push(task);
    } else {
      categories.later.push(task);
    }
  });

  return categories;
};

function TaskContainer({provided, task, openTaskModal}){

  const statusMenuRef = useRef(null);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);

  const changeStatus = (newStatus)=>{
    task.status = newStatus;
    setIsStatusMenuOpen(false);
  }

  const handleClickOutside = (event) => {
    if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
      setIsStatusMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.task}>
      <div className='flex items-center justify-between gap-1.5 text-xs text-gray-500 mb-3' >
        <div className='flex items-center gap-1.5 text-xs' >
          <FiFolder size={12}  />
          <p>Project name</p>
        </div>
        <div className='flex items-center gap-1.5 text-xs' >
          <FiClock size={12} />
          <span >08/04/2024</span>
        </div>
      </div>
      <p className='font-semibold mb-4' >{task.task}</p>
      <div className='flex items-center gap-2 text-gray-500' > 
        <div className={cx((styles.priority), {[styles.medium]: task.priority === "Medium", [styles.hight]: task.priority === "Hight"})} >
          {task.priority}
        </div>
        <span>•</span>
        <div className='relative w-max' >
          <button onClick={()=> setIsStatusMenuOpen(true)} className={cx(styles.status, {[styles.progress]: task.status === "En cours", [styles.finish]: task.status === "Terminé"})} >
            {task.status}
          </button>
          { 
            isStatusMenuOpen &&
            <div className={styles.floatStatusMenu} ref={statusMenuRef} >
              <ul>
                <li className={styles.currStatus} >{task.status}</li>
                <li onClick={()=> changeStatus("Pas commencé") } className={styles.status} >• Pas commencé</li>
                <li onClick={()=> changeStatus("En cours") } className={cx(styles.status, styles.progress)} >• En cours</li>
                <li onClick={()=> changeStatus("Terminé") } className={cx(styles.status, styles.finish)} >• Terminé</li>
              </ul>
            </div>
          }
        </div>
      </div>
      <div onClick={()=> openTaskModal(task) } className={styles.taskEditIcon} >
        <FiEdit2/>
      </div>
    </div>
  )
}

function KanbanColumn({ title, tasks, bgColor, titleColor, droppableId, openTaskModal }){

  return(
    <div className={styles.kanbanColumn} style={{backgroundColor: bgColor}}>
      <div className={styles.header} style={{color: titleColor}}> • {title}</div>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={styles.content}>
            {tasks.map((task, index) => (
              <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                {(provided) => (
                  <TaskContainer provided={provided} task={task} openTaskModal={openTaskModal} />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

function KanbanView({openTaskModal}) {
  const [tasks, setTasks] = useState(categorizeTasks(myTasks));

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        tasks[source.droppableId],
        source.index,
        destination.index
      );

      const newState = {
        ...tasks,
        [source.droppableId]: items,
      };

      setTasks(newState);
    } else {
      const result = move(
        tasks[source.droppableId],
        tasks[destination.droppableId],
        source,
        destination
      );

      setTasks({
        ...tasks,
        ...result,
      });
    }
  };

  // Reorder the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Moves an item from one list to another list
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  useEffect(() => {
    setTasks(categorizeTasks(myTasks));
    console.log(tasks);
  }, [myTasks]);

  return (
    <div className={styles.kanbanBoard}>
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.entries(tasks).map(([category, tasksInCategory]) => (
          <KanbanColumn 
            key={category}
            title={category.charAt(0).toUpperCase() + category.slice(1)} 
            tasks={tasksInCategory} 
            bgColor="#F5FAFC" 
            titleColor="#5A97BD" 
            droppableId={category} 
            openTaskModal={openTaskModal}
          />
        ))}
      </DragDropContext>
    </div>
  );
}

export default function MyTasks() {

  const filtersMenuRef = useRef(null);

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isStatusFiltersMenuOpen, setIsStatusFiltersMenuOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);
  const [isPriorityFiltersMenuOpen, setIsPriorityFiltersMenuOpen] = useState(false);
  const [filterPriority, setFilterPriority] = useState([]);
  const [isProjectFiltersMenuOpen, setIsProjectFiltersMenuOpen] = useState(false);
  const [filterProject, setFilterProject] = useState([]);

  const openTaskModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsTaskModalOpen(true);
    setTaskData(task);
  }
  const closeTaskModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTaskModalOpen(false);
    setTaskData({});
  }

  const toggleCheckStatus = (item)=>{
    if(filterStatus.some(it => it.id === item.id)){
      setFilterStatus(filterStatus.filter(it=> it.id !== item.id ));
    }else{
      setFilterStatus(oldArray => [...oldArray, item]);
    }
  }

  const toggleCheckPriority = (item)=>{
    if(filterPriority.some(it => it.id === item.id)){
      setFilterPriority(filterPriority.filter(it=> it.id !== item.id ));
    }else{
      setFilterPriority(oldArray => [...oldArray, item]);
    }
  }

  const toggleCheckProject = (item)=>{
    if(filterProject.some(it => it.id === item.id)){
      setFilterProject(filterProject.filter(it=> it.id !== item.id ));
    }else{
      setFilterProject(oldArray => [...oldArray, item]);
    }
  }

  const handleClickOutside = (event) => {
    if (filtersMenuRef.current && !filtersMenuRef.current.contains(event.target)) {
      setIsFilterMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div >
      <div className={styles.head} >
        <h2>My tasks</h2>
        <Row style={{gap: '12px'}} >
          <div className='relative' >
            <button onClick={()=> setIsFilterMenuOpen(true) } className={styles.subBtn} >
              Filter
            </button>
            {
              isFilterMenuOpen &&
              <div ref={filtersMenuRef} className={styles.floatMenuWrapper} onMouseLeave={()=> setIsFilterMenuOpen(false) } >
                <ul>
                  <li className={styles.floatMenuItem} onMouseEnter={()=> setIsStatusFiltersMenuOpen(true) } onMouseLeave={()=> setIsStatusFiltersMenuOpen(false)} >
                    Status
                    { isStatusFiltersMenuOpen && 
                    <div className={styles.subFiltersMenuWrapper} >
                      <ul className={styles.statusWrapper} >
                        { status.map((it)=>
                          <li key={it.id} >
                            <Row>
                              <CheckBox isChecked={filterStatus.some(item => item.id === it.id)} toggleCheck={()=> toggleCheckStatus(it) } />
                              <div className={cx((styles.status),{[styles.progress]: it.id === "2", [styles.finish]: it.id === "3"})} >
                                {it.title}
                              </div>
                            </Row>
                          </li>
                        )}
                      </ul>
                    </div>
                    }
                  </li> 
                  <li className={styles.floatMenuItem} onMouseEnter={()=> setIsPriorityFiltersMenuOpen(true) } onMouseLeave={()=> setIsPriorityFiltersMenuOpen(false)} >
                    Priorité
                    { isPriorityFiltersMenuOpen && 
                    <div className={styles.subFiltersMenuWrapper} >
                      <ul className={styles.statusWrapper} >
                        { priorities.map((it)=>
                          <li key={it.id} >
                            <Row>
                              <CheckBox isChecked={filterPriority.some(item => item.id === it.id)} toggleCheck={()=> toggleCheckPriority(it) } />
                              <div className={cx((styles.status),{[styles.medium]: it.id === "2", [styles.hight]: it.id === "3"})} >
                                {it.title}
                              </div>
                            </Row>
                          </li>
                        )}
                      </ul>
                    </div>
                    }
                  </li> 
                  <li className={styles.floatMenuItem} onMouseEnter={()=> setIsProjectFiltersMenuOpen(true) } onMouseLeave={()=> setIsProjectFiltersMenuOpen(false)} >
                    Project
                    { isProjectFiltersMenuOpen && 
                    <div className={styles.subFiltersMenuWrapper} >
                      <ul className={styles.statusWrapper} >
                        { projectsTest.map((it)=>
                          <li key={it.id} >
                            <Row>
                              <CheckBox isChecked={filterProject.some(item => item.id === it.id)} toggleCheck={()=> toggleCheckProject(it) } />
                              <div className='ml-2 text-sm' >
                                {it.title}
                              </div>
                            </Row>
                          </li>
                        )}
                      </ul>
                    </div>
                    }
                  </li> 
                </ul>
              </div>
            }
          </div>
          <button onClick={()=> openTaskModal({}) } className={styles.mainBtn} >
            New task
          </button>
        </Row>
      </div>
      <div className='mt-6' >
        <KanbanView openTaskModal={openTaskModal} />
      </div>
      <AnimatePresence>
        {
          isTaskModalOpen &&
          <SideModal isOpen={isTaskModalOpen} title="side modal" close={closeTaskModal} >
            <TaskModal close={closeTaskModal} task={taskData} />
          </SideModal>
        }
      </AnimatePresence>
    </div>
  )
}
