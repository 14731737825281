import './App.scss';
import './Styles/loading.css';
import {Suspense, useEffect} from 'react';
import {
  // createBrowserRouter,
  createHashRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  DoughnutController,
  PointElement,
  LineElement
} from 'chart.js';

import ErrorPage from "./error-page";
import Login from './Auth/Login';
import IsLoggedIn from './Auth/IsLoggedIn';

import Root from './Components/Root';
import AllProjects from './Components/AllProjects';
import Overview from './Components/Overview';
import Dashboard from './Components/Dashboard';
import Account from './Components/Account';
import Tasks from './Components/Tasks';

import MyTasks from './Components/MyTasks';
import Users from './Components/Users';
import Teams from './Components/Teams';
import UserDetails from './Components/UserDetails';

import { UserProvider } from './Contexts/UserContext';

import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import MasterRoot from "./Components/Master/MasterRoot";
import MasterDashboard from "./Components/Master/MasterDashboard";
import MasterClients from "./Components/Master/MasterClients";
import MasterNewClient from "./Components/Master/MasterNewClient";
import MasterEditClient from "./Components/Master/MasterEditClient";
import MasterShowClient from "./Components/Master/MasterShowClient";
import MasterUsers from "./Components/Master/MasterUsers";
import MasterAccount from "./Components/Master/MasterAccount";

registerLocale('fr', fr);
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title, DoughnutController, PointElement, LineElement);

const router = (isLoggedIn, isMaster) => createHashRouter([
  isMaster
    ?
    {
      path: "/",
      element: isLoggedIn ? <MasterRoot/> : <Navigate to="/login" />,
      children: [
        {
          path: "/",
          element: <MasterDashboard/>
        },{
          path: "/clients",
          element: <MasterClients/>
        },{
          path: "/new-client",
          element: <MasterNewClient/>
        },{
          path: "/edit-client/:clientId",
          element: <MasterEditClient/>
        }, {
          path: "/show-client/:clientId",
          element: <MasterShowClient/>
        },{
          path: "/users",
          element: <MasterUsers/>
        },{
          path: "/account",
          element: <MasterAccount/>
        },
      ]
    }
    :
    {
      path: "/",
      element: isLoggedIn ? <Root/> : <Navigate to="/login" />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <AllProjects />,
        },{
          path: "/overview/:id",
          element: <Overview />,
        },{
          path: "/my-tasks",
          element: <MyTasks/>
        },{
          path: "/dashboard",
          element: <Dashboard />,
        },{
          path: "/users",
          element: <Users />,
        },{
          path: "/teams",
          element: <Teams />,
        },{
          path: "/account",
          element: <Account/>
        },{
          path: "/user-info/:id",
          element: <UserDetails/>
        },{
          path: "/tasks",
          element: <Tasks/>
        }
      ],
    },
  {
    path: "/login",
    element: isLoggedIn ? <Navigate to="/" /> : <Login/>,
  }
]);

function App() {

  const authState = IsLoggedIn();

  if(authState.isLogged === null){
    return(
      <Suspense fallback="loading" >
        <div className="min-h-[90vh] relative flex items-center justify-center" >
          <div className='loading' >
            <div className='box' />
          </div>
        </div>
      </Suspense>
    )
  }

  if(authState.isLogged && authState.isMaster === null){
    return(
      <Suspense fallback="loading">
        <div className="min-h-[90vh] relative flex items-center justify-center">
          <div className='loading'>
            <div className='box'/>
          </div>
        </div>
      </Suspense>
    )
  }

  return (
    <Suspense fallback="loading">
      <UserProvider isLogged={authState.isLogged}>
        <RouterProvider router={router(authState.isLogged, authState.isMaster)} />
      </UserProvider>
    </Suspense>
  );
}

export default App;
