import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const SwalModal = (t, title, type) => {
  MySwal.fire({
    title: <i>{t(title)}</i>,
    // text: '',
    icon: type,
    showCancelButton: true,
    showConfirmButton: false,
    // confirmButtonText: 'Yes, go ahead!',
    cancelButtonText: t('COMMON.CANCEL'),
    buttonsStyling: true,
  });
}
