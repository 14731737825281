import React, {useEffect, useRef, useState} from 'react';
import {FiAlertCircle, FiCheckCircle, FiChevronRight, FiHome, FiXOctagon} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";

import cx from "classnames";

import { ReactComponent as NewClientSVG } from '../../Assets/newCustumor.svg';
import DatePicker from "react-datepicker";
import {SketchPicker} from "react-color";
import {useNavigate, useParams} from "react-router-dom";
import {createTenant, showTenant, updateTenant} from "../../services/tenantService";
import Modal from "../Modal";
import Swal from "sweetalert2";
import {SwalModal} from "../../Ressources/SwalModal";

function ClientCreatedModal({close, closeAndAdd}){

  const {t} = useTranslation();

  return(
    <div className='flex flex-col items-center'>
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5'>
        <FiCheckCircle size={84} className='text-green-700 '/>
        <h1 className='text-center text-xl font-semibold mb-2'>{t("MASTER.CLIENTS.CLIENT_UPDATED_MSG")}</h1>
      </div>
      <div className='w-full mt-6 flex items-center justify-center px-10'>
        <button onClick={() => close()}
                className='hover:text-purple-light min-w-24 hover:border-purple-light flex items-center justify-center gap-2 px-4 py-1.5 rounded-lg border text-sm font-semibold text-gray-600'>
          {t("COMMON.BACK")}
        </button>
      </div>
    </div>
  )
}

function MasterEditClient(props) {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const params  = useParams();
  const {control, register, handleSubmit, reset, setValue, formState: {errors}} = useForm();

  const colorSelectRef = useRef(null);

  const [isColorSelectOpen, setIsColorSelectOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("rgba(120, 120, 199, 1)");
  const [loading, setLoading] = useState(false);
  const [isClientCreated, setIsClientCreated] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const requiredFields = {
    intitule: true,
    code: true,
    prenom: true,
    nom: true,
    email: true,
    qualite: true,
    startDate: true,
    endDate: true,
    logo: false,
    logoWhite: false,
    color: false,
    text: false,
  };

  const closeClientCreatedModal = () => {
    setIsClientCreated(false);
    navigate(-1);
  }
  const closeAndAddClient = ()=>{
    setIsClientCreated(false);
    setSelectedColor("rgba(0, 127, 255, 1)");
    reset();
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorCode("");
    const formData = new FormData();

    const clientData = {
      id: params.clientId,
      code: data.code,
      email: data.email,
      endDate: data.endDate,
      prenom: data.firstName,
      intitule: data.intitule,
      nom: data.lastName,
      qualite: data.quality,
      startDate: data.startDate,
      text: data.text,
      color: selectedColor
    }

    formData.append('tenantDto', new Blob([JSON.stringify(clientData)], { type: 'application/json' }));
    if(data.logo != null && data.logo !== ""){
      formData.append('logo', data.logo[0]);
    }
    if(data.whiteLogo != null && data.whiteLogo !== ""){
      formData.append('whiteLogo', data.whiteLogo[0]);
    }

    updateTenant(formData)
      .then((res)=>{
        setIsClientCreated(true);
      })
      .catch((err)=> {
        // console.log(err);
        SwalModal(t, `ERRORS.${err.response.data.message}`, 'error');
        setErrorCode(err.response.data.message);
      })
      .finally(() => setLoading(false));
  }

  const handleClickOutside = (event) => {
    if (colorSelectRef.current && !colorSelectRef.current.contains(event.target)) {
      setIsColorSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(Object.keys(params).length > 0){
      showTenant(params.clientId)
        .then((res)=>{
          setValue("code", res.code);
          setValue("email", res.email);
          setValue("endDate", new Date(res.endDate));
          setEndDate(new Date(res.endDate));
          setValue("firstName", res.prenom);
          setValue("intitule", res.intitule);
          setValue("lastName", res.nom);
          setValue("quality", res.qualite);
          setValue("startDate", new Date(res.startDate));
          setStartDate(new Date(res.startDate));
          setValue("text", res.text);
          setSelectedColor(res.color);
          validateEmail(res.email);
        })
        .catch((err)=>{console.log(err)})
    }
  },[params]);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 onClick={()=> navigate(-1) } className="text-xs font-semibold text-gray-500 cursor-pointer hover:underline hover:text-purple-light">{t("MASTER.NAV.CLIENTS")}</h4>
        <span className="text-xs font-semibold flex items-center">
          <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("MASTER.NAV.EDIT_CLIENT")}</h4>
      </div>
      <div className="flex gap-10" >
        <div className="w-[60%]">
          {/*{ errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}*/}
          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
            <div className="bg-white p-2 pt-8 relative border rounded-lg mb-5">
              <p
                className="bg-white border px-2 py-1 text-xs font-semibold absolute top-[-12px] left-3 rounded-full">
                {t("MASTER.CLIENTS.NEW_CLIENT.TITLES.INFORMATION")}
              </p>
              <div className="grid grid-cols-3 gap-3 mb-5">
                <div className='flex flex-col col-span-2'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.INTITULE")} {requiredFields.intitule && "*"}</label>
                    {errors.intitule &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='border rounded-lg px-2 py-1.5 text-sm'
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.INTITULE")}
                    {...register("intitule", {required: requiredFields.intitule})}
                  />
                </div>
                <div className='flex flex-col col-span-1'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.CODE")} {requiredFields.code && "*"}</label>
                    {errors.code &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='border rounded-lg px-2 py-1.5 text-sm'
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.CODE")}
                    {...register("code", {
                      required: requiredFields.code,
                      pattern: {
                        value: /^[a-z]+$/,
                      }
                    })}
                    onChange={(e) => {
                      e.target.value = e.target.value.toLowerCase().replace(/[^a-z]/g, '');
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 mb-5">
                <div className='flex flex-col'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.LAST_NAME")} {requiredFields.nom && "*"}</label>
                    {errors.lastName &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='border rounded-lg px-2 py-1.5 text-sm'
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.LAST_NAME")}
                    {...register("lastName", {required: requiredFields.nom})}
                  />
                </div>
                <div className='flex flex-col'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.FIRST_NAME")} {requiredFields.prenom && "*"}</label>
                    {errors.firstName &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='border rounded-lg px-2 py-1.5 text-sm'
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.FIRST_NAME")}
                    {...register("firstName", {required: requiredFields.prenom})}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 mb-5">
                <div className='flex flex-col relative'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.EMAIL")} {requiredFields.email && "*"}</label>
                    {errors.email ?
                      errors.email.type === "required"
                        ? <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>
                        : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
                      : null
                    }
                  </div>
                  <input
                    className='border rounded-lg px-2 py-1.5 text-sm pr-10'
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.EMAIL")}
                    {...register("email", {required: requiredFields.email, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/})}
                    onChange={(e) => validateEmail(e.target.value)}
                  />
                  {
                    isEmailValide
                      ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.7rem]'/>
                      : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.7rem]'/>
                  }
                </div>
                <div className='flex flex-col'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.QUALITY")} {requiredFields.qualite && "*"}</label>
                    {errors.quality &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='border rounded-lg px-2 py-1.5 text-sm'
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.QUALITY")}
                    {...register("quality", {required: requiredFields.qualite})}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 ">
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label
                      className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.START_DATE")} {requiredFields.startDate && "*"}</label>
                    {errors.startDate && <label
                      className='text-xs text-red-700 font-semibold max-w-[50%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <Controller
                    control={control}
                    name='startDate'
                    rules={{required: requiredFields.startDate}}
                    render={({field}) => (
                      <DatePicker
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          setStartDate(date);
                        }}
                        locale={i18n.language}
                        isClearable
                        dateFormat="dd/MM/yyyy"
                        className='border rounded-lg px-2 py-1.5 text-sm w-full'
                        wrapperClassName='w-full'
                        placeholderText={t("MASTER.CLIENTS.NEW_CLIENT.FORM.START_DATE")}
                        maxDate={endDate}
                      />
                    )}
                  />
                </div>
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>
                      {t("MASTER.CLIENTS.NEW_CLIENT.FORM.END_DATE")} {requiredFields.endDate && "*"}
                    </label>
                    {errors.endDate && <label
                      className='text-xs text-red-700 font-semibold max-w-[50%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <Controller
                    control={control}
                    name='endDate'
                    rules={{required: requiredFields.endDate}}
                    render={({field}) => (
                      <DatePicker
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          setEndDate(date);
                        }}
                        locale={i18n.language}
                        isClearable
                        dateFormat="dd/MM/yyyy"
                        className='border rounded-lg px-2 py-1.5 text-sm w-full'
                        wrapperClassName='w-full'
                        placeholderText={t("MASTER.CLIENTS.NEW_CLIENT.FORM.END_DATE")}
                        minDate={startDate}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="bg-white p-2 pt-8 relative border rounded-lg mb-4">
              <p
                className="bg-white border px-2 py-1 text-xs font-semibold absolute top-[-12px] left-3 rounded-full">
                {t("MASTER.CLIENTS.NEW_CLIENT.TITLES.THEME")}
              </p>
              <div className="grid grid-cols-3 gap-3 mb-5">
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>
                      {t("MASTER.CLIENTS.NEW_CLIENT.FORM.LOGO")} {requiredFields.logo && "*"}
                    </label>
                    {errors.logo && <label
                      className='text-xs text-red-700 font-semibold max-w-[30%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <Controller
                    name="logo"
                    control={control}
                    defaultValue=""
                    rules={{required: requiredFields.logo}}
                    render={({field}) =>
                      <input
                        className="block w-full text-sm text-slate-500
                          file:mr-4 file:py-1.5 file:px-2 file:rounded-md
                          file:border-0 file:text-sm file:font-semibold
                          file:bg-sky-50 file:text-sky-700 file:cursor-pointer
                          hover:file:bg-sky-100 border rounded-lg appearance-none"
                        type="file"
                        {...field}
                      />
                    }
                  />
                </div>
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>
                      {t("MASTER.CLIENTS.NEW_CLIENT.FORM.WHITE_LOGO")} {requiredFields.logoWhite && "*"}
                    </label>
                    {errors.whiteLogo && <label
                      className='text-xs text-red-700 font-semibold max-w-[30%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <Controller
                    name="whiteLogo"
                    control={control}
                    defaultValue=""
                    rules={{required: requiredFields.logoWhite}}
                    render={({field}) =>
                      <input
                        className="block w-full text-sm text-slate-500
                          file:mr-4 file:py-1.5 file:px-2 file:rounded-md
                          file:border-0 file:text-sm file:font-semibold
                          file:bg-sky-50 file:text-sky-700 file:cursor-pointer
                          hover:file:bg-sky-100 border rounded-lg appearance-none"
                        type="file"
                        {...field}
                      />
                    }
                  />
                </div>
                <div className="relative">
                  <div className='flex items-center justify-between'>
                    <label className='text-xs mb-0.5 ml-0 text-gray-500 font-semibold'>
                      {t("MASTER.CLIENTS.NEW_CLIENT.FORM.COLOR")} {requiredFields.color && "*"}
                    </label>
                    {selectedColor.length === 0 && <label
                      className='text-xs text-red-700 font-semibold max-w-[30%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <div
                    onClick={() => setIsColorSelectOpen(true)}
                    style={{backgroundColor: selectedColor}}
                    className={cx("w-8 h-8 rounded-md border")}
                  />
                  {
                    isColorSelectOpen &&
                    <div ref={colorSelectRef} className='absolute top-10 left-0 z-30 w-max'>
                      <SketchPicker
                        onChangeComplete={(col) => setSelectedColor(`rgba(${col.rgb.r}, ${col.rgb.g}, ${col.rgb.b}, ${col.rgb.a})`)}
                        color={selectedColor}
                      />
                    </div>
                  }
                </div>
              </div>
              <div className='flex flex-col'>
                <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                  <label className='text-xs text-gray-500 font-semibold'>
                    {t("MASTER.CLIENTS.NEW_CLIENT.FORM.TEXT")} {requiredFields.text && "*"}
                  </label>
                  {errors.text &&
                    <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                </div>
                <textarea
                  rows={3}
                  className='border rounded-lg px-2 py-1.5 text-sm'
                  placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.TEXT")}
                  {...register("text", {required: requiredFields.text})}
                />
              </div>
            </div>
            <div className="flex items-center justify-end">
              {
                !loading &&
                <button
                  className="cursor-pointer bg-purple-light px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
                  {t("COMMON.SAVE")}
                </button>
              }
              {
                loading &&
                <div className="relative border border-purple-light px-4 py-2 h-9 w-24 rounded-lg">
                  <div className="loader"/>
                </div>
              }
            </div>
          </form>
        </div>
        <div className="w-[40%]">
          <h1 className="text-5xl font-thin text-center">{t("MASTER.CLIENTS.NEW_CLIENT.EDIT_TITLE")}</h1>
          <NewClientSVG height="100%" width="100%"/>
        </div>
      </div>
      <Modal isOpen={isClientCreated} close={closeClientCreatedModal} >
        <ClientCreatedModal close={closeClientCreatedModal} closeAndAdd={closeAndAddClient} />
      </Modal>
    </div>
  );
}

export default MasterEditClient;
