import React, {useEffect, useState} from 'react';
import {FiChevronRight, FiHome, FiUsers} from "react-icons/fi";
import {FaUsers} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {getBarChartStatistique, setMainStatistique} from "../../services/statistiqueService";

// Registering necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function MasterDashboard(props) {

  const {t} = useTranslation();

  const [statistique, setStatistique] = useState({});
  const [barChartStatistique, setBarChartStatistique] = useState({});

  const chartDataTest = {
    labels: ['Client A', 'Client B', 'Client C'],
    datasets: [
      {
        label: 'Active Users',
        data: [120, 150, 180],
        backgroundColor: '#7878c7',
      },
      {
        label: 'Inactive Users',
        data: [80, 60, 100],
        backgroundColor: '#c9c9e9',
      }
    ]
  };

  const fetchMainStatistics = async () => {
    setMainStatistique()
      .then((res) => { setStatistique(res) })
      .catch((err) => { console.log(err) });
  }

  const fetchBarChartStatistique = async ()=>{
    getBarChartStatistique()
      .then((res) => {
        console.log(res)
        const labels = res.map(item => item.clientName);
        const activeUsers = res.map(item => item.nbrActiveUsers);
        const inactiveUsers = res.map(item => item.nbrUsers - item.nbrActiveUsers);
        const transformedChartData = {
          labels,
          datasets: [
            {
              label: t("MASTER.DASHBOARD.NUMBERS.ACTIVE_USERS"),
              data: activeUsers,
              backgroundColor: '#007FFF',
            },
            {
              label: t("MASTER.DASHBOARD.NUMBERS.INACTIVE_USERS"),
              data: inactiveUsers,
              backgroundColor: 'rgba(0, 127, 255, 0.5)',
            }
          ]
        };

        setBarChartStatistique(transformedChartData);
      })
      .catch((err) => { console.log(err) });
  }

  useEffect(() => {
    fetchMainStatistics();
    fetchBarChartStatistique();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("MASTER.NAV.DASHBOARD")}</h4>
      </div>
      <div className="grid grid-cols-5 gap-3 mb-6">
        <div className="bg-white rounded-lg p-3 ">
          <div className="flex items-center gap-2 mb-3">
            <div className="bg-[#ededf8] rounded-md p-2 w-max">
              <FaUsers size={16} className="text-azure"/>
            </div>
            <p className="text-xs font-semibold text-gray-400">{t("MASTER.DASHBOARD.NUMBERS.CLIENTS")}</p>
          </div>
          <p className="text-3xl font-semibold ">{statistique?.nbrClients}</p>
        </div>
        <div className="bg-white rounded-lg p-3 ">
          <div className="flex items-center gap-2 mb-3">
            <div className="bg-[#ededf8] rounded-md p-2 w-max">
              <FiUsers size={16} className="text-azure"/>
            </div>
            <p className="text-xs font-semibold text-gray-400">{t("MASTER.DASHBOARD.NUMBERS.USERS")}</p>
          </div>
          <p className="text-3xl font-semibold ">{statistique?.nbrUsers}</p>
        </div>
        <div className="bg-white rounded-lg p-3 ">
          <div className="flex items-center gap-2 mb-3">
            <div className="bg-[#ededf8] rounded-md p-2 w-max">
              <FaUsers size={16} className="text-azure"/>
            </div>
            <p className="text-xs font-semibold text-gray-400">{t("MASTER.DASHBOARD.NUMBERS.ACTIVE_USERS")}</p>
          </div>
          <p className="text-3xl font-semibold ">{statistique?.nbrActiveUsers}</p>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-3 mb-4">
        <div className="col-span-3 min-h-[40vh] bg-white rounded-lg p-3">
          { Object.keys(barChartStatistique).length > 0 &&
            <Bar
              data={barChartStatistique}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: t("MASTER.DASHBOARD.CHART_TITLE"),
                  },
                },
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                  }
                }
              }}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default MasterDashboard;
