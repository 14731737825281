import styles from '../Styles/taskChat.module.scss';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FiSend, FiUser, FiX } from 'react-icons/fi';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

export default function TaskChat({close, taskChatData}) {

  const {t} = useTranslation();
  const [message, setMessage] = useState("");

  return (
    <div className='fixed top-0 left-0 h-[100vh] w-[100vw] z-50' >
      <motion.div 
        initial={{ right: '-20rem' }}
        animate={{ right: '1rem' }} 
        exit={{ right: '-20rem' }} 
        transition={{ type: 'spring', stiffness: 100, duration: 500 }}
        className='absolute top-[1%] h-[98%] w-80 bg-white z-50 p-3 customShadow rounded-xl' 
      >
        <div className='flex flex-col justify-between h-full' >
          <div className='flex items-center justify-between' >
            <h2 className='font-semibold' >{t("NEW_TASK_MODAL.TASK_CHAT.TITLE")}</h2>
            <FiX className='cursor-pointer' onClick={()=> close() } />
          </div>
          <div>
            <div className='flex flex-col-reverse gap-3 overflow-y-auto max-h-[80vh]' >
              {/* my message */}
              <div>
                <div className='flex items-center gap-2' >
                  <div className='border p-2 rounded-full' >
                    <FiUser size={16} />
                  </div>
                  <div className='border rounded-r-full rounded-b-full px-4 py-1.5 w-max text-sm' >
                    test teeeest
                  </div>
                </div>
                <p className='text-gray-500 text-xs ml-12 mt-1' >1h ago • user full name</p>
              </div>
              {/* other users message */}
              <div className='flex flex-col items-end' >
                <div className='flex items-center gap-2' >
                  <div className='border rounded-l-full rounded-b-full px-4 py-1.5 w-max text-sm' >
                    test teeeest
                  </div>
                  <div className='border p-2 rounded-full' >
                    <FiUser size={16} />
                  </div>
                </div>
                <p className='text-gray-500 text-xs mr-12 mt-1' >1h ago • user full name</p>
              </div>
              { Array.from({ length: 18 }, (_, i) => i).reverse().map((it)=>
                <div>
                  <div className='flex items-center gap-2' >
                    <div className='border p-2 rounded-full' >
                      <FiUser size={16} />
                    </div>
                    <div className='border rounded-r-full rounded-b-full px-4 py-1.5 w-max text-sm' >
                      test teeeest {it}
                    </div>
                  </div>
                  <p className='text-gray-500 text-xs ml-12 mt-1' >1h ago • user name</p>
                </div> 
              )}
            </div>
            <div className='w-full flex items-center justify-between gap-3 mt-4' >
              <input
                className='border rounded-full p-2 px-3 text-sm w-full'
                placeholder='Ecrire votre message...'
                value={message}
                onChange={(e)=> setMessage(e.target.value) }
              />
              <div className={cx('border p-2 rounded-full cursor-pointer', {'bg-purple-light text-white hover:bg-purple-dark': message.length > 0, 'text-gray-500': message.length <= 0})} >
                <FiSend />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
