import React from 'react';
import styles from '../Styles/modal.module.scss';
import { motion } from 'framer-motion';

export default function SideModal({children, isOpen, close, title, customStyle}) {

  const contentVariants = {
    hidden: {
      x: '100%',
      opacity: 0
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      x: '100%',
      opacity: 0,
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <div className={styles.sideModal}>
      <motion.div
        className={styles.content}
        style={customStyle}
        variants={contentVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {children}
      </motion.div>
      { isOpen && <div className={styles.bg} onClick={close} />}
    </div>
  );

}