import api from "./api";

export const downloadFile = async (name)=>{
  try {
    const response = await api.get(`/admin/download/${name}`, {responseType: 'blob'});
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
