import React, { useState, useRef, useEffect } from 'react';
import styles from '../Styles/allProjects.module.scss';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle, FiEdit2, FiEdit3, FiPlus, FiSearch, FiTrash, FiUser, FiX, FiXCircle, FiXOctagon } from 'react-icons/fi';
import { useForm } from "react-hook-form";
import { motion } from 'framer-motion';
import cx from 'classnames';

import Modal from './Modal';
import {getAllUsers, getUsersInfoByIds} from "../services/usersService";
import {createTeam, deleteTeam, getAllTeams, updateTeam} from "../services/unityService";
import {getAllTenantUsers, getUserByTenantByPage} from "../services/tenantUsersService";

function NewTeamModal({close, initData}){

  const { t } = useTranslation();
  const respoSelectRef = useRef(null);
  const usersSelectRef = useRef(null);

  const [name, setName] = useState("");
  const [userSearchInput, setUserSearchInput] = useState("");
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [respoUsers, setRespoUsers] = useState([]);
  const [team, setTeam] = useState([]);
  const [respoSearchInput, setRespoSearchInput] = useState("");
  const [isRespoSelectOpen, setIsRespoSelectOpen] = useState(false);
  const [respo, setRespo] = useState({});
  const [loading, setLoading] = useState(false);

  const selectUser = (user) => {
    if (!team.map((it) => it.id).includes(user.id)) {
      setTeam(oldArray => [...oldArray, user]);
      setUserSearchInput("");
    }
  }
  const removeUser = (user) => {
    setTeam(team.filter(it => it.id !== user.id));
  }

  const selectRespo = (user)=>{
    setRespo(user);
    setRespoSearchInput("");
    setIsRespoSelectOpen(false);
  }

  const submit = ()=>{
    setLoading(true);
    const teamData = {
      "name": name,
      "responsible": respo.id,
      "members": team.map((it) => it.id),
    }
    if(initData && Object.keys(initData).length > 0){
      console.log(teamData);
      updateTeam(teamData, initData.id)
        .then((res) => {
          close();
        })
        .catch((err)=> console.log(err) )
        .finally(()=> setLoading(false) );
    }else{
      createTeam(teamData)
        .then((res) => {
          close();
        })
        .catch((err)=> console.log(err) )
        .finally(()=> setLoading(false) );
    }
  }

  const submitDeleteTeam = async ()=>{
    await deleteTeam(initData.id)
      .then((res) => {
        close();
      })
      .catch((err)=> console.log(err) );
  }

  const fetchUsers = () => {
    // getAllUsers({keyWord: userSearchInput})
    //   .then((res) => {
    //     setAllUsers(res);
    //   })
    //   .catch((err) => console.log(err));
    getAllTenantUsers({keyWord: userSearchInput})
      .then(users => { setAllUsers(users);})
      .catch(err => console.log(err));
  }

  const fetchRespoUsers = () => {
    // getAllUsers({keyWord: respoSearchInput})
    //   .then((res) => {
    //     setRespoUsers(res);
    //   })
    //   .catch((err) => console.log(err));
    getAllTenantUsers({keyWord: respoSearchInput})
      .then(users => { setRespoUsers(users);})
      .catch(err => console.log(err));
  }

  const getRespo = async (id)=>{
    getUsersInfoByIds([id])
      .then((res) => {
        setRespo(res[0]);
      })
      .catch((err) => console.log(err));
  }

  const getTeamMembers = async (ids)=>{
    getUsersInfoByIds(ids)
      .then((res) => {
        setTeam(res);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (userSearchInput.length > 0) {
      fetchUsers();
    }
  }, [userSearchInput]);

  useEffect(() => {
    if (respoSearchInput.length > 0) {
      fetchRespoUsers();
    }
  }, [respoSearchInput]);

  useEffect(()=>{
    if(userSearchInput.length > 0){
      setIsUserSelectOpen(true);
    }else{
      setIsUserSelectOpen(false);
    }
  },[userSearchInput]);

  useEffect(()=>{
    if(respoSearchInput.length > 0){
      setIsRespoSelectOpen(true);
    }else{
      setIsRespoSelectOpen(false);
    }
  },[respoSearchInput]);

  const handleClickOutside = (event) => {
    if (respoSelectRef.current && !respoSelectRef.current.contains(event.target)) {
      setIsRespoSelectOpen(false);
    }
    if (usersSelectRef.current && !usersSelectRef.current.contains(event.target)) {
      setIsUserSelectOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(initData && Object.keys(initData).length > 0){
      setName(initData.name);
      initData.members && getTeamMembers(initData.members);
      initData.responsible && getRespo(initData.responsible);
    }
  }, [initData]);

  return(
    <div className='min-h-[50vh] min-w-[600px] flex flex-col justify-between' >
      <div>
        <div className='grid grid-cols-2 gap-2 mb-5' >
          <div className="w-full" >
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>{t("TEAMS.MODAL.NAME_INPUT")}</label>
            </div>
            <input
              placeholder={t("TEAMS.MODAL.NAME_INPUT")}
              className='text-sm border py-2 px-3 h-10 rounded-lg w-full'
              value={name}
              onChange={(e)=> setName(e.target.value) }
            />
          </div>
          <div className='relative'>
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>{t("TEAMS.MODAL.RESPO_INPUT")}</label>
            </div>
            <div className='border py-2 px-3 h-10 flex items-center justify-between rounded-lg gap-2'>
              {Object.keys(respo).length > 0
                ?
                <div className='flex items-center justify-between w-full'>
                  {respo.firstName} {respo.lastName}
                  <FiXCircle size={14} className='cursor-pointer' onClick={() => setRespo({})}/>
                </div>
                : <>
                  <input
                    placeholder={t("TEAMS.MODAL.RESPO_INPUT")}
                    className='text-sm w-full'
                    value={respoSearchInput}
                    onChange={(e) => setRespoSearchInput(e.target.value)}
                  />
                  <FiSearch size={16} className='text-gray-400'/>
                </>
              }
            </div>
            {
              isRespoSelectOpen &&
              <div ref={respoSelectRef}
                   className='border p-2 text-sm rounded-lg w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[3.8rem]'>
                <ul className='flex flex-col gap-2'>
                  {
                    respoUsers.map((it) =>
                      <li key={it.id} onClick={() => selectRespo(it)}
                          className={cx('p-1 px-2 rounded-lg flex items-center gap-3 cursor-pointer hover:bg-purple-100 hover:text-black', {'bg-purple-light text-white': respo.id === it.id})}>
                        <div className='border p-1 rounded-full'>
                          <FiUser size={18}/>
                        </div>
                        <p className='font-semibold'>{it.firstName} {it.lastName}</p>
                      </li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='border rounded-lg p-2 pt-4 relative'>
          <div className='absolute top-[-10px] left-4 px-2 bg-white text-xs font-semibold text-gray-500' >
            {t("TEAMS.MODAL.MEMBERS_TITLE")}
          </div>
          <div className='border py-2 px-3 h-10 flex items-center justify-between rounded-lg gap-2' >
            <input
              placeholder={t("TEAMS.MODAL.USERS_INPUT")}
              className='text-sm w-full'
              value={userSearchInput}
              onChange={(e)=> setUserSearchInput(e.target.value) }
            />
            <FiSearch size={16} className='text-gray-400' />
          </div>
          {
            isUserSelectOpen &&
            <div ref={usersSelectRef} className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[3rem]' >
              <ul className='flex flex-col gap-2' >
                {
                  allUsers.map((it)=>
                    <li key={it.id} onClick={()=> selectUser(it) } className={cx('p-1 px-2 rounded-lg flex items-center gap-3 cursor-pointer hover:bg-purple-100 hover:text-black', {'bg-purple-light text-white': team.includes(it)})} >
                      <div className='border p-1 rounded-full' >
                        <FiUser size={18} />
                      </div>
                      <p className='font-semibold' >{it.firstName} {it.lastName}</p>
                    </li>
                  )
                }
              </ul>
            </div>
          }
          {team.length > 0 &&
            team.map((it) =>
              <div key={it.id} className='flex flex-col gap-3 mt-4'>
                <div className={styles.projectTeam_usersWrapper}>
                  <div className='flex items-center gap-3'>
                    <div className='border p-1 rounded-full'>
                      <FiUser size={28}/>
                    </div>
                    <div>
                      <p className='text-sm font-semibold'>{it.firstName} {it.lastName}</p>
                      {/*<p className='text-xs font-semibold text-gray-500'>full stack developer</p>*/}
                    </div>
                  </div>
                  <FiTrash onClick={() => removeUser(it)} className='text-red-700 cursor-pointer' size={16}/>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className={cx('mt-6 flex items-center', {'justify-end': !initData, 'justify-between': initData && Object.keys(initData).length > 0})} >
        { initData && Object.keys(initData).length > 0 &&
          <button onClick={()=> submitDeleteTeam() } className="px-4 py-2 border rounded-lg hover:text-white hover:bg-red-700 border-red-700 text-red-700 font-semibold text-sm" >
            {t("COMMON.DELETE")}
          </button>
        }
        {/*<button onClick={()=> submit() } className={styles.mainBtn} >*/}
        {/*  {t("COMMON.SAVE")}*/}
        {/*</button>*/}
        {!loading &&
          <button onClick={() => submit()} className={styles.mainBtn}>
            {t("COMMON.SAVE")}
          </button>
        }
        {loading &&
          <div className="flex items-center justify-center px-4 w-28 h-10 bg-purple-light rounded-lg">
            <div className="loader"/>
          </div>
        }
      </div>
    </div>
  )
}

function TeamContainer({it, openNewTeamModal}){

  const [respo, setRespo] = useState({});

  const getRespo = async ()=>{
    getUsersInfoByIds([it.responsible])
      .then((res) => {
        setRespo(res[0]);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if(Object.keys(it).length > 0){
      getRespo();
    }
  }, [it]);

  return (
    <div key={it.id} className='group border rounded-lg p-2 flex flex-col relative '>
      <div className='w-full flex items-center justify-between'>
        <div className='relative min-h-12'>
          <div
            className='customShadow w-10 h-10 flex items-center justify-center border rounded-full bg-[#a5a5da] absolute top-0 left-0'>
            <FiUser size={22} color='#fff'/>
          </div>
          <div
            className='customShadow w-10 h-10 flex items-center justify-center border rounded-full bg-[#ededf8] absolute top-0 left-5'>
            <FiUser size={22} color='#000'/>
          </div>
          <div
            className='customShadow w-max px-2.5 text-xs font-semibold h-10 text-gray-500 flex items-center justify-center border border-gray-500 rounded-full bg-white absolute top-0 left-10'>
            {it.members.length} membres
          </div>
        </div>
        <div className='flex items-center gap-1'>
          {/* <button className='border border-white hover:border-purple-light hover:text-purple-light rounded-full p-2 text-gray-500' >
                <FiPlus/>
              </button> */}
          <button
            onClick={() => openNewTeamModal(it)}
            className='border border-white hover:border-purple-light hover:text-purple-light rounded-full p-2 text-gray-500'>
            <FiEdit2/>
          </button>
        </div>
      </div>
      <div className='mt-2'>
        <h2 className='text-lg font-semibold' onClick={()=> console.log(respo) } >{it.name}</h2>
        <h4 className='text-xs font-semibold text-gray-500'>{respo.firstName} {respo.lastName}</h4>
      </div>
    </div>
  )
}

export default function Teams() {

  const {t} = useTranslation();

  const [isNewTeamModalOpen, setIsNewTeamModalOpen] = useState(false);
  const [editTeamData, setEditTeamData] = useState({});
  const [teams, setTeams] = useState([]);

  const openNewTeamModal = (data) => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setEditTeamData(data)
    setIsNewTeamModalOpen(true);
  }
  const closeNewTeamModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewTeamModalOpen(false);
    setEditTeamData({});
    fetchTeams();
  }

  const fetchTeams = async () => {
    getAllTeams()
      .then((res) => {
        setTeams(res);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    fetchTeams();
  }, []);

  return (
    <div className='mt-6'>
      <div className='flex items-center justify-between mb-4'>
        <h2 className='text-xl font-semibold'>{t("TEAMS.TITLE")}</h2>
        <div className='flex items-center gap-2'>
          <button onClick={() => openNewTeamModal()}
                  className='bg-purple-light hover:bg-purple-dark text-white text-sm px-6 py-2.5 rounded-lg font-semibold'>
            {t("TEAMS.ADD_BTN")}
          </button>
        </div>
      </div>
      <div className='grid grid-cols-3 gap-4'>
        {
          teams.map((it) => <TeamContainer it={it} key={it.id} openNewTeamModal={openNewTeamModal} /> )
        }
      </div>

      <Modal isOpen={isNewTeamModalOpen} close={closeNewTeamModal} title={t("TEAMS.ADD_BTN")}>
        <NewTeamModal close={closeNewTeamModal} initData={editTeamData} />
      </Modal>
    </div>
  )
}
