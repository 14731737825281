
import React from 'react';
import styles from './defaultComponents.module.scss';
import cx from "classnames";
import {FiCheck, FiEye, FiEyeOff} from 'react-icons/fi';

export const Button = ({ children, type = 'primary-btn', onClick, loading, ...props }) => {
    return <button {...props} className={cx(styles[type], {[styles["loading-btn"]]:loading})} disabled={loading} onClick={()=> onClick()} >{children}</button>;
};

export const Input = ({ label, type = 'text', isPassHidden, setIsPassHidden, ...props }) => {
    return (
        <div className={styles.inputWrapper}>
            {label && <label className={styles.label}>{label}</label>}
          <div className="w-full relative" >
            {
              type === "password"
                ? <input className={styles.input} type={isPassHidden ? type : "text"} {...props} />
                : <input className={styles.input} type={type} {...props} />
            }
            {
              type === "password" &&
              <button onClick={()=> setIsPassHidden(!isPassHidden) } className="absolute translate-y-[-50%] top-1/2 right-3" >
                {
                  isPassHidden
                    ? <FiEye className="text-gray-600" />
                    : <FiEyeOff className="text-gray-600" />
                }
              </button>
            }
          </div>
        </div>
    );
};

export const Grid_2 = ({children, ...props})=>{
    return(
        <div className={styles["grid-2"]} {...props} >{children}</div>
    )
}
export const Grid_3 = ({children, ...props})=>{
    return(
        <div className={styles["grid-3"]} {...props} >{children}</div>
    )
}

export const Row = ({children, ...props})=>{
    return(
        <div className={styles["row"]} {...props} >{children}</div>
    )
}

export const Row_Between = ({children, ...props})=>{
    return(
        <div className={styles["row-between"]} {...props} >{children}</div>
    )
}

export const Card = ({children, title, btn, onClickBtn, ...props})=>{
    return(
        <div className={styles.card} {...props} >
            <Row_Between style={{marginBottom: '12px'}} >
                <span className={styles.cardTitle} >{title}</span>
                { btn && <button className={styles.cardBtn} onClick={()=> onClickBtn() } >{btn}</button>}
            </Row_Between>
            {children}
        </div>
    )
}

export const CheckBox = ({isChecked, toggleCheck, ...props})=>{
    return(
        <div className={cx(styles.checkBox, {[styles.checkedCheckBox]: isChecked})} onClick={()=> toggleCheck() } {...props} >
            {isChecked && <FiCheck size={14} color='#2a2a66' />}
        </div>
    )
}


export const ProgressBar = ({ progress }) => {
    return (
        <div className={styles.progressBarWrapper} >
            <div className={styles.progressBar}>
                <div className={styles.progress} style={{ width: `${progress}%` }} />
            </div>
            <span className={styles.progressLabel}>{`${progress}%`}</span>
        </div>
    );
};
