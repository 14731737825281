import React, {useEffect, useRef, useState} from 'react';
import {FiCheckCircle, FiChevronRight, FiEdit, FiEye, FiHome, FiXOctagon} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";

import cx from "classnames";
import { saveAs } from 'file-saver';
import { ReactComponent as NewClientSVG } from '../../Assets/newCustumor.svg';
import DatePicker from "react-datepicker";
import {SketchPicker} from "react-color";
import {useNavigate, useParams} from "react-router-dom";
import {createTenant, showTenant, updateTenant} from "../../services/tenantService";
import {downloadFile} from "../../services/gestionService";

function MasterShowClient(props) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const params  = useParams();
  const { control, register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

  const colorSelectRef = useRef(null);

  const [isColorSelectOpen, setIsColorSelectOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("rgba(120, 120, 199, 1)");
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isEditClient, setIsEditClient] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [whiteLogoUrl, setWhiteLogoUrl] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorCode("");
    const formData = new FormData();

    const clientData = {
      id: params.clientId,
      code: data.code,
      email: data.email,
      endDate: data.endDate,
      prenom: data.firstName,
      intitule: data.intitule,
      nom: data.lastName,
      qualite: data.quality,
      startDate: data.startDate,
      text: data.text,
      color: selectedColor
    }
    formData.append('tenantDto', new Blob([JSON.stringify(clientData)], { type: 'application/json' }));
    if(data.logo != null && data.logo !== ""){
      formData.append('logo', data.logo[0]);
    }
    if(data.whiteLogo != null && data.whiteLogo !== ""){
      formData.append('whiteLogo', data.whiteLogo[0]);
    }

    updateTenant(formData)
      .then((res)=>{
        navigate(-1);
      })
      .catch((err)=> {
        console.log(err);
        setErrorCode(err.response.data.message)
      })
      .finally(() => setLoading(false));
  }

  const fetchLogo = async (name)=>{
    await downloadFile(name)
      .then((res)=> {
        const blobUrl = URL.createObjectURL(res);
        setLogoUrl(blobUrl);
      })
      .catch((err)=> { console.log(err); })
  }
  const fetchWhiteLogo = async (name)=>{
    await downloadFile(name)
      .then((res)=> {
        const blobUrl = URL.createObjectURL(res);
        setWhiteLogoUrl(blobUrl);
      })
      .catch((err)=> { console.log(err); })
  }

  const handleClickOutside = (event) => {
    if (colorSelectRef.current && !colorSelectRef.current.contains(event.target)) {
      setIsColorSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(Object.keys(params).length > 0){
      showTenant(params.clientId)
        .then((res)=>{
          setValue("code", res.code);
          setValue("email", res.email);
          setValue("endDate", new Date(res.endDate));
          setEndDate(new Date(res.endDate));
          setValue("firstName", res.prenom);
          setValue("intitule", res.intitule);
          setValue("lastName", res.nom);
          setValue("quality", res.qualite);
          setValue("startDate", new Date(res.startDate));
          setStartDate(new Date(res.startDate));
          setValue("text", res.text);
          setSelectedColor(res.color);
          validateEmail(res.email);
          res.logo && fetchLogo(res.logo.storedFilename);
          res.whiteLogo && fetchWhiteLogo(res.whiteLogo.storedFilename);
        })
        .catch((err)=>{console.log(err)})
    }
  },[params])

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 onClick={()=> navigate(-1) } className="text-xs font-semibold text-gray-500 cursor-pointer hover:underline hover:text-purple-light">{t("MASTER.NAV.CLIENTS")}</h4>
        <span className="text-xs font-semibold flex items-center">
          <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("MASTER.NAV.SHOW_CLIENT")}</h4>
      </div>
      <div className="flex gap-10" >
        <div className="w-[60%]">
          { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
            <div className="bg-white p-2 pt-8 relative border rounded-lg mb-5">
              <p
                className="bg-white border px-2 py-1 text-xs font-semibold absolute top-[-12px] left-3 rounded-full">
                {t("MASTER.CLIENTS.NEW_CLIENT.TITLES.INFORMATION")}
              </p>
              {/*<button*/}
              {/*  type="button"*/}
              {/*  onClick={()=> setIsEditClient(!isEditClient) }*/}
              {/*  className="bg-white border border-purple-light text-purple-light hover:bg-purple-light hover:text-white px-2 py-1 text-xs font-semibold absolute top-[-12px] right-3 rounded-full">*/}
              {/*  {*/}
              {/*    isEditClient*/}
              {/*      ? <span className="flex items-center gap-2" ><FiEye/> {t("MASTER.CLIENTS.SEE_BTN")}</span>*/}
              {/*      : <span className="flex items-center gap-2" ><FiEdit/> {t("MASTER.CLIENTS.EDIT_BTN")}</span>*/}
              {/*  }*/}
              {/*</button>*/}
              <div className="grid grid-cols-3 gap-3 mb-5">
                <div className='flex flex-col col-span-2'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.INTITULE")} *</label>
                    {errors.intitule &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className={cx('rounded-lg px-2 py-1.5 text-sm', {'border': isEditClient})}
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.INTITULE")}
                    {...register("intitule", {required: true})}
                    disabled={!isEditClient}
                  />
                </div>
                <div className='flex flex-col col-span-1'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.CODE")} *</label>
                    {errors.code &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='rounded-lg px-2 py-1.5 text-sm'
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.CODE")}
                    {...register("code", {required: true})}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 mb-5">
                <div className='flex flex-col'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.LAST_NAME")} *</label>
                    {errors.lastName &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className={cx('rounded-lg px-2 py-1.5 text-sm', {'border': isEditClient})}
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.LAST_NAME")}
                    {...register("lastName", {required: true})}
                    disabled={!isEditClient}
                  />
                </div>
                <div className='flex flex-col'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.FIRST_NAME")} *</label>
                    {errors.firstName &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className={cx('rounded-lg px-2 py-1.5 text-sm', {'border': isEditClient})}
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.FIRST_NAME")}
                    {...register("firstName", {required: true})}
                    disabled={!isEditClient}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 mb-5">
                <div className='flex flex-col relative'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.EMAIL")} *</label>
                    {errors.email ?
                      errors.email.type === "required"
                        ? <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>
                        : <label onClick={() => console.log(errors.email)}
                                 className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
                      : null
                    }
                  </div>
                  <input
                    className={cx('rounded-lg px-2 py-1.5 text-sm pr-10', {'border': isEditClient})}
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.EMAIL")}
                    {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                    onChange={(e) => validateEmail(e.target.value)}
                    disabled={!isEditClient}
                  />
                  {
                    isEmailValide
                      ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.7rem]'/>
                      : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.7rem]'/>
                  }
                </div>
                <div className='flex flex-col'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.QUALITY")} *</label>
                    {errors.quality &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className={cx('rounded-lg px-2 py-1.5 text-sm', {'border': isEditClient})}
                    placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.QUALITY")}
                    {...register("quality", {required: true})}
                    disabled={!isEditClient}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 ">
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label
                      className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.START_DATE")} *</label>
                    {errors.startDate && <label
                      className='text-xs text-red-700 font-semibold max-w-[50%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <Controller
                    disabled={!isEditClient}
                    control={control}
                    name='startDate'
                    rules={{required: true}}
                    render={({field}) => (
                      <DatePicker
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          setStartDate(date);
                        }}
                        isClearable={isEditClient}
                        disabled={!isEditClient}
                        dateFormat="dd/MM/yyyy"
                        className={cx('rounded-lg px-2 py-1.5 text-sm w-full', {'border': isEditClient})}
                        wrapperClassName='w-full'
                        placeholderText={t("MASTER.CLIENTS.NEW_CLIENT.FORM.START_DATE")}
                        maxDate={endDate}
                      />
                    )}
                  />
                </div>
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label
                      className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.END_DATE")} *</label>
                    {errors.endDate && <label
                      className='text-xs text-red-700 font-semibold max-w-[50%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <Controller
                    disabled={!isEditClient}
                    control={control}
                    name='endDate'
                    rules={{required: true}}
                    render={({field}) => (
                      <DatePicker
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          setEndDate(date);
                        }}
                        isClearable={isEditClient}
                        disabled={!isEditClient}
                        dateFormat="dd/MM/yyyy"
                        className={cx('rounded-lg px-2 py-1.5 text-sm w-full', {'border': isEditClient})}
                        wrapperClassName='w-full'
                        placeholderText={t("MASTER.CLIENTS.NEW_CLIENT.FORM.END_DATE")}
                        minDate={startDate}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="bg-white p-2 pt-8 relative border rounded-lg mb-4">
              <p
                className="bg-white border px-2 py-1 text-xs font-semibold absolute top-[-12px] left-3 rounded-full">
                {t("MASTER.CLIENTS.NEW_CLIENT.TITLES.THEME")}
              </p>
              <div className="grid grid-cols-3 gap-3 mb-5">
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label
                      className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.LOGO")}</label>
                    {errors.logo && <label
                      className='text-xs text-red-700 font-semibold max-w-[30%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <div className={cx("w-20 h-20 mt-2 flex items-center justify-center", {"border rounded-lg p-1": logoUrl.length === 0})}>
                    {logoUrl.length > 0
                      ? <img src={logoUrl} alt='client-logo' className="w-full h-full object-contain"/>
                      : <p className="text-sm font-semibold text-gray-500 text-center">{t("COMMON.EMPTY_FIELD")}</p>
                    }
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label
                      className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.WHITE_LOGO")}</label>
                    {errors.whiteLogo && <label
                      className='text-xs text-red-700 font-semibold max-w-[30%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <div className={cx("w-20 h-20 mt-2 flex items-center justify-center", {"border rounded-lg p-1": whiteLogoUrl.length === 0})} >
                    {whiteLogoUrl.length > 0
                      ? <img src={whiteLogoUrl} alt='client-logo' className="w-full h-full object-contain"/>
                      : <p className="text-sm font-semibold text-gray-500 text-center">{t("COMMON.EMPTY_FIELD")}</p>
                    }
                  </div>
                </div>
                <div className="relative">
                  <div className='flex items-center justify-between'>
                    <label
                      className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.COLOR")}</label>
                    {errors.whiteLogo && <label
                      className='text-xs text-red-700 font-semibold max-w-[30%] truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <button
                    disabled={!isEditClient}
                    onClick={() => setIsColorSelectOpen(true)}
                    style={{backgroundColor: selectedColor}}
                    className={cx("w-8 h-8 rounded-md border")}
                  />
                  {
                    isColorSelectOpen &&
                    <div ref={colorSelectRef} className='absolute top-10 left-0 z-30 w-max'>
                      <SketchPicker
                        onChangeComplete={(col) => setSelectedColor(`rgba(${col.rgb.r}, ${col.rgb.g}, ${col.rgb.b}, ${col.rgb.a})`)}
                        color={selectedColor}
                      />
                    </div>
                  }
                </div>
              </div>
              <div className='flex flex-col'>
                <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                  <label
                    className='text-xs text-gray-500 font-semibold'>{t("MASTER.CLIENTS.NEW_CLIENT.FORM.TEXT")} *</label>
                  {errors.text &&
                    <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                </div>
                <textarea
                  disabled={!isEditClient}
                  rows={3}
                  className={cx('rounded-lg px-2 py-1.5 text-sm', {'border': isEditClient})}
                  placeholder={t("MASTER.CLIENTS.NEW_CLIENT.FORM.TEXT")}
                  {...register("text", {required: false})}
                />
              </div>
            </div>
            { isEditClient &&
              <div className="flex items-center justify-end">
                {
                  !loading &&
                  <button
                    className="cursor-pointer bg-purple-light px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
                    {t("COMMON.EDIT")}
                  </button>
                }
                {
                  loading &&
                  <div className="relative border border-purple-light px-4 py-2 h-9 w-24 rounded-lg">
                    <div className="loader"/>
                  </div>
                }
              </div>
            }
          </form>
        </div>
        <div className="w-[40%]">
          <h1 className="text-5xl font-thin text-center">{t("MASTER.CLIENTS.NEW_CLIENT.SHOW_TITLE")}</h1>
          <NewClientSVG height="100%" width="100%"/>
        </div>
      </div>
    </div>
  );
}

export default MasterShowClient;
