import api from "./api";

export const getAllTenantUsers = async (data)=>{
  try {
    const response = await api.post('/admin/client_users/list', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getUserByTenantByPage = async (page, size, search) =>{
  const params = {
    page: page,
    size: size,
    sort: `id,asc`,
  };

  const formData = new FormData();
  formData.append('filter', new Blob([JSON.stringify({
    keyWord: search,
  })], { type: 'application/json' }));

  try {
    const response = await api.post('/admin/client_users/page', formData, { params });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getTenantUserById = async (id)=>{
  try {
    const response = await api.get(`/admin/client_users/show/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const createTenantUser = async (formData) =>{

  try {
    const response = await api.post('/admin/client_users/create', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateTenantUser = async (formData) =>{

  try {
    const response = await api.put('/admin/client_users/update', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteTenantUser = async (id)=>{
  try {
    const response = await api.delete(`/admin/client_users/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const getAllTenantRoles = async ()=>{
  try {
    const response = await api.get('/admin/roles/all_tenant');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
